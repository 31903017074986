import React from "react";
// import classNames from "classnames";

import styles from "./styles.module.scss";
import { doEditParking } from "../../utils/Firebase";

// COMPONENTS
// import Icon from "../../ui/Icon";
// import FlagButtons from "../FlagButtons";

class FormParking extends React.Component {
    initState = {
        cena: "",
        oznaceni: "",
        podlazi: "",
        vymera: "",
    };
    state = {
        ...this.initState,
        init: true,
    };

    imageRef = React.createRef();

    static getDerivedStateFromProps(props, state) {
        if (state.init && !props.loading) {
            return {
                ...props.data,
            };
        }
        // Return null if the state hasn't changed
        return null;
    }

    onSubmit = (event) => {
        event.preventDefault();
        const data = this.state;
        delete data["init"];
        this.setState({ init: true });
        doEditParking(data);
    };

    onChange = (event) => {
        const value = event.target.value;
        this.setState({ init: false });
        this.setState({ [event.target.name]: value });
    };

    render() {
        const { cena, oznaceni, podlazi, vymera } = this.state;
        const { titleButton } = this.props;

        return (
            <div className={styles.formprojects_form}>
                <form onSubmit={this.onSubmit}>
                    <div className={styles.formprojects_input_title}>
                        Označení
                    </div>
                    <input
                        className={styles.formprojects_input}
                        name="oznaceni"
                        value={oznaceni}
                        onChange={this.onChange}
                    />

                    <div className={styles.formprojects_input_title}>
                        Podlaží
                    </div>
                    <input
                        className={styles.formprojects_input}
                        name="podlazi"
                        value={podlazi}
                        onChange={this.onChange}
                    />

                    <div className={styles.formprojects_input_title}>Cena</div>
                    <input
                        className={styles.formprojects_input}
                        name="cena"
                        value={cena}
                        onChange={this.onChange}
                    />

                    <div className={styles.formprojects_input_title}>
                        Výměra
                    </div>
                    <input
                        className={styles.formprojects_input}
                        name="vymera"
                        value={vymera}
                        onChange={this.onChange}
                    />

                    <button
                        className={styles.formprojects_submit_button}
                        type="submit"
                    >
                        {titleButton}
                    </button>
                </form>
            </div>
        );
    }
}

export default FormParking;
