import React from "react";
import { Link } from "react-router-dom";

import { AuthUserContext, withAuthorization } from "../../utils/Session";

import PasswordChangeForm from "../../ui-admin/PasswordChange";

import AdminFormContainer from "../../ui-admin/AdminFormContainer";
import styles from "./style.module.scss";
import * as ROUTES from "../../constants/routes";
import Icon from "../../ui/Icon";

const AccountPage = () => (
    <AuthUserContext.Consumer>
        {(authUser) => (
            <AdminFormContainer>
                <Link to={ROUTES.ADMIN}>
                    <Icon name="cancel-music" className={styles.icon} />
                </Link>
                <div className={styles.page}>
                    <div className={styles.email}>{authUser.email}</div>
                    <PasswordChangeForm />
                </div>
            </AdminFormContainer>
        )}
    </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(AccountPage);
