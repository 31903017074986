import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Component } from "react";
// COMPONENTS
import IntroImage from "../../components/IntroImage";
import About from "../../components/About";
import Features from "../../components/Features";
import InfoTable from "../../components/InfoTable";
import Timeline from "../../components/Timeline";
import Location from "../../components/Location";
import HouseMap from "../../components/HouseMap";
import Vizualization from "../../components/Vizualization";
import Standards from "../../components/Standards";
import Financing from "../../components/Financing";
import Partners from "../../components/Partners";
import Modal from "../../components/Modal";
// UI
import Lock from "../../ui/Lock";
import Loader from "../../ui/Loader";
import Footer from "../../ui/Footer";
// UTILS
import db from "../../firebase";
import { ref, onValue } from "firebase/database";

class Landing extends Component {
    state = {
        loading: true,
        database: {
            cellar: [],
            flats: [],
            floors: [],
            parking: [],
            contact: {
                one: {},
                two: {},
                three: {},
            },
            pdf: {
                pp1: { url: "" },
                np1: { url: "" },
                np2: { url: "" },
                np3: { url: "" },
                np4: { url: "" },

                stan1: { url: "" },
                stan2: { url: "" },
                stan3: { url: "" },
                stan4: { url: "" },
                stan5: { url: "" },
            },
            standards: {
                flats: {},
                house: {},
                system: {},
            },
            settings: {
                locked: "",
                pass: "",
            },
            texts: {
                about: {},
                infotable: {},
                intro: {},
                timeline: {},
            },
            pins: {},
        },
    };

    componentDidMount() {
        onValue(ref(db, "database"), (snapshot) => {
            if (snapshot.exists()) {
                this.setState({
                    database: snapshot.val(),
                    loading: false,
                });
            } else {
                console.log("No data available");
            }
        });
    }

    render() {
        const { database, loading } = this.state;

        return (
            <div id="appID">
                {!loading && <Lock database={database} />}
                <Loader loading={loading} />
				{/* <Modal /> */}

                <IntroImage id="oprojektu" />
                <About database={database} />
                <Features database={database} id="lokalita" />
                <InfoTable database={database} />
                <Timeline database={database} />
                <Location database={database} />
                <HouseMap
                    database={database}
                    id="prehledbytu"
                    idPricelist="prehledcen"
                />
                <Vizualization id="vizualizace" />
                <Standards database={database} id="standardy" />
                <Financing database={database} id="financovani" />
                <Partners database={database} id="partneri" />
                <Footer database={database} id="kontakt" />
            </div>
        );
    }
}

export default Landing;
