import React, { Component } from "react";
import classNames from "classnames";
import { TweenLite } from "gsap";
// SCSS
import styles from "./style.module.scss";
import fonts from "../../vars/fonts/fonts.module.scss";
import margin from "../../vars/margin/margin.module.scss";
// UI
import Title from "../../ui/Title";
import Section from "../../ui/Section";
import SvgIcon from "../../ui/SvgIcon";
import SectionDropdown from "../../ui/SectionDropdown";
// PDF
import pp1 from "./assets/pdf/1pp.pdf";
import pp2 from "./assets/pdf/2pp.pdf";
import pp1sklepy from "./assets/pdf/sklepy1pp.pdf";

class Pricelist extends Component {
    scrollToContact = () => {
        TweenLite.to(window, 1, {
            scrollTo: { y: "#kontakt", autoKill: false },
        });
    };

    render() {
        const { database, onClick, id } = this.props;

        return (
            <Section id={id} className={fonts.center}>
                <Title text="Přehled cen" />
				
				{/* <div className={classNames(styles.msginfo, fonts.regular )}>
					Zvýhodněná nabídka bytů! <a href="#kontakt">Kontaktujte nás pro více informací.</a>
				</div> */}

                <div className={classNames(fonts.h3, margin.medium_bottom)}>
                    BYTOVÉ JEDNOTKY
                </div>
                <SectionDropdown
                    className={margin.large_bottom}
                    initialHeight={200}
                    time={1}
                >
                    <div className={classNames(fonts.left, styles.pricelist)}>
                        {database.flats
                            .filter((flat) => flat.isAvailable)
                            .map((flat, idx) => (
                                <div
                                    key={`flat${idx}`}
                                    className={styles.pricelist_item}
                                >
                                    <div className={fonts.medium}>
                                        <div>Podlaží</div>
                                        <div>Označení</div>
                                        <div>Dispozice</div>
                                        <div>Výměra</div>
                                        <div>Lodžie</div>
                                        <div>Cena</div>
                                        <div>PDF</div>
                                    </div>

                                    <div
                                        className={classNames(
                                            fonts.regular,
                                            styles.pricelist_item_center
                                        )}
                                    >
                                        <div>{flat.podlazi}</div>
                                        <div>{flat.oznaceni}</div>
                                        <div>{flat.dispozice}</div>
                                        <div>{flat.vymera}</div>
                                        <div>{flat.lodzie}</div>
                                        <div>
											{flat.isRk ? (
												<div
													onClick={this.scrollToContact}
													className={styles.label}
												>
													Na dotaz v RK
												</div>
											) : (
												flat.cena
											)}
                                            {/* <div
                                                onClick={this.scrollToContact}
                                                className={styles.label}
                                            >
                                                Na dotaz v RK
                                            </div> */}
                                        </div>

                                        <div>
                                            <a
                                                className={
                                                    styles.pricelist_secondary_item_pdflink
                                                }
                                                href={require("../../pdf/" +
                                                    flat.pdf +
                                                    ".pdf")}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <SvgIcon
                                                    name="pdf"
                                                    className={
                                                        styles.pricelist_svg_pricelist
                                                    }
                                                />
                                            </a>
                                        </div>
                                    </div>

                                    <div
                                        onClick={() =>
                                            onClick(
                                                flat.id,
                                                flat.house === 1 ? "a" : "b"
                                            )
                                        }
                                    >
                                        <SvgIcon
                                            name="lupa"
                                            className={styles.pricelist_svg}
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div
                        className={classNames(
                            fonts.center,
                            fonts.regular,
                            styles.pricelist_secondary,
                            styles.pricelist_secondary_desktop
                        )}
                    >
                        <div
                            className={classNames(
                                fonts.medium,
                                styles.pricelist_secondary_item
                            )}
                        >
                            <div>Podlaží</div>
                            <div>Označení</div>
                            <div>Dispozice</div>
                            <div>Výměra</div>
                            <div>Lodžie</div>
                            <div>Sklepní kóje</div>
                            <div>Cena</div>
                            <div>PDF</div>
                            <div> </div>
                        </div>

                        {database.flats
                            .filter((flat) => flat.isAvailable)
                            .map((flat, idx) => (
                                <div
                                    key={`flat${idx}`}
                                    className={classNames(
                                        styles.pricelist_secondary_item
                                    )}
                                >
                                    <div>{flat.podlazi}</div>
                                    <div>{flat.oznaceni}</div>
                                    <div>{flat.dispozice}</div>
                                    <div>{flat.vymera}</div>
                                    <div>{flat.lodzie}</div>
                                    <div
                                        style={{
                                            display: "flex",
                                            margin: "auto",
                                        }}
                                    >
                                        <SvgIcon
                                            name="checked"
                                            className={
                                                styles.pricelist_svg_checked
                                            }
                                        />
                                    </div>
                                    <div>
                                        {flat.isRk ? (
                                            <div
                                                onClick={this.scrollToContact}
                                                className={styles.label}
                                            >
                                                Na dotaz v RK
                                            </div>
                                        ) : (
                                            flat.cena
                                        )}
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <a
                                            className={
                                                styles.pricelist_secondary_item_pdflink
                                            }
                                            href={require("../../pdf/" +
                                                flat.pdf +
                                                ".pdf")}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <SvgIcon
                                                name="pdf"
                                                className={
                                                    styles.pricelist_svg_pricelist
                                                }
                                            />
                                        </a>
                                    </div>

                                    <div
                                        onClick={() =>
                                            onClick(
                                                flat.id,
                                                flat.house === 1 ? "a" : "b"
                                            )
                                        }
                                    >
                                        <SvgIcon
                                            name="lupa"
                                            className={styles.pricelist_svg}
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                </SectionDropdown>

                <div className={classNames(fonts.h3, margin.medium_bottom)}>
                    PARKOVACÍ STÁNÍ
                </div>
                <SectionDropdown
                    className={margin.large_bottom}
                    initialHeight={200}
                    time={1}
                >
                    <div
                        className={classNames(
                            fonts.center,
                            fonts.regular,
                            styles.pricelist_secondary
                        )}
                    >
                        <div
                            className={classNames(
                                fonts.medium,
                                styles.pricelist_secondary_item
                            )}
                        >
                            <div>Podlaží</div>
                            <div>Označení</div>
                            <div>Výměra</div>
                            <div>Cena</div>
                            <div>PDF</div>
                        </div>

                        {database.parking.map((parking, idx) => (
                            <div
                                key={`parking${idx}`}
                                className={styles.pricelist_secondary_item}
                            >
                                <div>{parking.podlazi}</div>
                                <div>{parking.oznaceni}</div>
                                <div>{parking.vymera}</div>
                                <div>
                                    {parking.cena}
                                    {/* <div
                                        onClick={this.scrollToContact}
                                        className={styles.label}
                                    >
                                        Na dotaz v RK
                                    </div> */}
                                </div>
                                <div style={{ display: "flex" }}>
                                    <a
                                        className={
                                            styles.pricelist_secondary_item_pdflink
                                        }
                                        href={
                                            parking.podlazi === "1.PP"
                                                ? pp1
                                                : pp2
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <SvgIcon
                                            name="pdf"
                                            className={styles.pricelist_svg}
                                        />
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </SectionDropdown>

                <div className={classNames(fonts.h3, margin.medium_bottom)}>
                    SKLEPNÍ KOJE
                </div>
                <div className={classNames(fonts.medium, margin.medium_bottom)}>
                    <a
                        className={styles.pricelist_icon_big}
                        href={pp1sklepy}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <SvgIcon
                            name="pdf"
                            className={styles.pricelist_svg_big}
                        />
                        1. PP
                    </a>
                </div>

                <div
                    className={classNames(
                        fonts.regular,
                        styles.pricelist_taxinfo
                    )}
                >
                    {database.texts.cenik &&
                        database.texts.cenik.split("\n").map((i, key) => {
                            return (
                                <p key={key}>
                                    {i}
                                    <br />
                                </p>
                            );
                        })}
                </div>
            </Section>
        );
    }
}

export default Pricelist;
