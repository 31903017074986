import uuidv4 from "uuid/v4";
import moment from "moment";

import {
    getAuth,
    signOut,
    updatePassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { getDatabase, ref, update, set, remove } from "firebase/database";

const auth = getAuth();
const db = getDatabase();

// Authentication

const doSignIn = (email, password) =>
    signInWithEmailAndPassword(auth, email, password);

const doUpdatePassword = (password) =>
    updatePassword(auth.currentUser, password);

const doSignOutUser = () => signOut(auth);

const doResetPassword = (email) => sendPasswordResetEmail(auth, email);

export { doSignIn, doSignOutUser, doUpdatePassword, doResetPassword };

// Realtime Database

const doEditFlat = (data) => {
    const updates = {};
    updates[`database/flats/${data.id}`] = data;
    return update(ref(db), updates);
};

const doEditFloor = (data) => {
    const updates = {};
    updates[`database/floors/${data.id}`] = data;
    return update(ref(db), updates);
};

const doEditParking = (data) => {
    const updates = {};
    updates[`database/parking/${data.id}`] = data;
    return update(ref(db), updates);
};

/*
const doEditContact = (data, src) => {
    const updates = {};
    updates[`database/contacts/${src}`] = data;
    return update(ref(db), updates);
};

const doEditTexts = (data, src) => {
    const updates = {};
    updates[`database/texts/${src}`] = data;
    return update(ref(db), updates);
};

const doAddStandard = (data, src) => {
    const uuid = uuidv4();
    const date = moment().unix();

    return set(ref(db, `database/standards/${src}/${uuid}`), {
        uuid,
        date,
        ...data,
    });
};

const doEditStandard = (data, src) => {
    const updates = {};
    updates[`database/standards/${src}/${data.uuid}`] = data;
    return update(ref(db), updates);
};

const doDeleteStandard = (uuid, src) => {
    remove(ref(db, `database/standards/${src}/${uuid}`));
};
 */

const doAddPin = (data) => {
    const uuid = uuidv4();
    const date = moment().unix();

    return set(ref(db, `database/pins/${uuid}`), {
        uuid,
        date,
        ...data,
    });
};

const doEditPin = (data) => {
    const updates = {};
    updates[`database/pins/${data.uuid}`] = data;
    return update(ref(db), updates);
};

const doDeletePin = (uuid) => {
    remove(ref(db, `database/pins/${uuid}`));
};

const doChangeLockStatus = (data) => {
    const updates = {};
    updates[`database/settings/locked`] = data;
    return update(ref(db), updates);
};

const doChangeLockPass = (data) => {
    const updates = {};
    updates[`database/settings/pass`] = data;
    return update(ref(db), updates);
};

export {
    doEditFlat,
    doEditFloor,
    doEditParking,
    // doEditContact,
    // doEditTexts,
    // doAddStandard,
    // doEditStandard,
    // doDeleteStandard,
    doAddPin,
    doEditPin,
    doDeletePin,
    doChangeLockStatus,
    doChangeLockPass,
};
