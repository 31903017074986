import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import Landing from "./pages/Landing";
import SignIn from "./pages/SignIn";
import Admin from "./pages/Admin";
import Account from "./pages/Account";
import PasswordForget from "./pages/PasswordForget";

import Container from "./ui/Container";
import Header from "./ui/Header";

import NoTouch from "./utils/NoTouch";
import { withAuthentication } from "./utils/Session";
import * as ROUTES from "./constants/routes";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <div className="app">
                    <NoTouch />
                    <Header />
                    <Container>
                        <AnimatedSwitch
                            atEnter={{ opacity: 0 }}
                            atLeave={{ opacity: 0 }}
                            atActive={{ opacity: 1 }}
                        >
                            <Route
                                path={ROUTES.LANDING}
                                component={Landing}
                                exact={true}
                            />
                            <Route path={ROUTES.SIGN_IN} component={SignIn} />
                            <Route path={ROUTES.ADMIN} component={Admin} />
                            <Route path={ROUTES.ACCOUNT} component={Account} />
                            <Route
                                path={ROUTES.PASSWORD_FORGET}
                                component={PasswordForget}
                            />
                            <Route component={Landing} />
                        </AnimatedSwitch>
                    </Container>
                </div>
            </BrowserRouter>
        );
    }
}

export default withAuthentication(App);
