import React, { Component } from "react";
import { TweenLite } from "gsap";
import { withRouter } from "react-router-dom";

import styles from "./style.module.scss";
import logo from "./assets/logo_white.svg";

import Link from "../Link";
// import GoUp from "../GoUp";

class Header extends Component {
    constructor(props) {
        super(props);

        this.headerRef = React.createRef();
        this.menuRef = React.createRef();
        this.topLineRef = React.createRef();
        this.middleLineRef = React.createRef();
        this.bottomLineRef = React.createRef();

        this.state = {
            isOpen: false,
            width: 0,
        };
    }

    toggleMenu = () => {
        if (this.state.isOpen) {
            this.closeMenu();
            this.setState({ isOpen: false });
        } else {
            this.openMenu();
            this.setState({ isOpen: true });
        }
    };
    openMenu = () => {
        TweenLite.to(this.middleLineRef.current, 0.2, {
            autoAlpha: 0,
        });
        TweenLite.to(this.topLineRef.current, 0.3, {
            y: 9,
            rotation: 23,
        });
        TweenLite.to(this.bottomLineRef.current, 0.3, {
            y: -9,
            rotation: -23,
        });
        TweenLite.to(this.menuRef.current, 0.3, {
            autoAlpha: 1,
            height: 327,
        });
    };
    closeMenu = () => {
        TweenLite.to(this.middleLineRef.current, 0.2, {
            delay: 0.1,
            autoAlpha: 1,
        });
        TweenLite.to(this.topLineRef.current, 0.3, {
            y: 0,
            rotation: 0,
        });
        TweenLite.to(this.bottomLineRef.current, 0.3, {
            y: 0,
            rotation: 0,
        });
        TweenLite.to(this.menuRef.current, 0.3, {
            autoAlpha: 0,
            height: 0,
        });
    };

    scrollTo = (id, offsetY = 53) => () => {
        TweenLite.to(window, 1, {
            scrollTo: { y: id, offsetY, autoKill: false },
        });
        this.toggleMenu();
    };

    render() {
        // <GoUp hidden={!isOpen} />

        return (
            this.props.location.pathname === "/" && (
                <div
                    className={styles.header}
                    id="headerID"
                    ref={this.headerRef}
                >
                    <div className={styles.menu}>
                        <div
                            className={styles.logo}
                            style={{ backgroundImage: `url(${logo})` }}
                        />
                        <div className={styles.navigation}>
                            <Link
                                name="o projektu"
                                onClick={this.scrollTo("#oprojektu", 0)}
                            />
                            <Link
                                name="lokalita"
                                onClick={this.scrollTo("#lokalita")}
                            />
                            <Link
                                name="přehled bytů"
                                onClick={this.scrollTo("#prehledbytu")}
                            />
                            <Link
                                name="přehled cen"
                                onClick={this.scrollTo("#prehledcen")}
                            />
                            <Link
                                name="vizualizace"
                                onClick={this.scrollTo("#vizualizace")}
                            />
                            <Link
                                name="standardy"
                                onClick={this.scrollTo("#standardy")}
                            />
                            <Link
                                name="financování"
                                onClick={this.scrollTo("#financovani")}
                            />
                            <Link
                                name="partneři"
                                onClick={this.scrollTo("#partneri")}
                            />
                            <Link
                                name="kontakt"
                                onClick={this.scrollTo("#kontakt", 0)}
                            />
                        </div>
                    </div>

                    <div className={styles.menu_mobile_header}>
                        <div
                            className={styles.logo_small}
                            style={{
                                backgroundImage: `url(${logo})`,
                            }}
                        />
                        <div
                            className={styles.animation}
                            onClick={this.toggleMenu}
                        >
                            <div
                                className={styles.line}
                                ref={this.topLineRef}
                            />
                            <div
                                className={styles.line}
                                ref={this.middleLineRef}
                            />
                            <div
                                className={styles.line}
                                ref={this.bottomLineRef}
                            />
                        </div>
                    </div>

                    <div className={styles.menu_mobile} ref={this.menuRef}>
                        <div className={styles.menu_mobile_content}>
                            <Link
                                name="o projektu"
                                onClick={this.scrollTo("#oprojektu", 0)}
                            />
                            <Link
                                name="lokalita"
                                onClick={this.scrollTo("#lokalita")}
                            />
                            <Link
                                name="přehled bytů"
                                onClick={this.scrollTo("#prehledbytu")}
                            />
                            <Link
                                name="přehled cen"
                                onClick={this.scrollTo("#prehledcen")}
                            />
                            <Link
                                name="vizualizace"
                                onClick={this.scrollTo("#vizualizace")}
                            />
                            <Link
                                name="standardy"
                                onClick={this.scrollTo("#standardy")}
                            />
                            <Link
                                name="financovní"
                                onClick={this.scrollTo("#financovani")}
                            />
                            <Link
                                name="partneři"
                                onClick={this.scrollTo("#partneri", 30)}
                            />
                            <Link
                                name="kontakt"
                                onClick={this.scrollTo("#kontakt", 0)}
                            />
                        </div>
                    </div>
                </div>
            )
        );
    }
}

export default withRouter(Header);
