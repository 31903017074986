import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const config = {
    apiKey: "AIzaSyDZ2DAE3ySJPNV8LbXiIe3BjvYib3dC4hU",
    authDomain: "vlhorni.firebaseapp.com",
    databaseURL: "https://vlhorni.firebaseio.com",
    projectId: "vlhorni",
    storageBucket: "vlhorni.appspot.com",
    messagingSenderId: "1071499328955",
    appId: "1:1071499328955:web:2c3f68a479e1482c34d89e",
};

export const app = initializeApp(config);

export default getDatabase(app);
