import React, { Component } from "react";
import classNames from "classnames";

import styles from "./style.module.scss";
import fonts from "../../vars/fonts/fonts.module.scss";
import margin from "../../vars/margin/margin.module.scss";
import { doChangeLockStatus, doChangeLockPass } from "../../utils/Firebase";

import Switch from "../../ui/Switch";

class LockAdmin extends Component {
    static defaultProps = {
        database: {},
    };

    initState = {
        pass: "",
    };
    state = {
        init: true,
    };

    static getDerivedStateFromProps(props, state) {
        if (state.init && !props.loading) {
            return {
                pass: props.database.settings.pass,
            };
        }
        // Return null if the state hasn't changed
        return null;
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.setState({ init: true });
        doChangeLockPass(this.state.pass);
    };

    onChange = (event) => {
        const value = event.target.value;
        this.setState({ init: false });
        this.setState({ [event.target.name]: value });
    };

    onClickSwitch = () =>
        doChangeLockStatus(!this.props.database.settings.locked);

    render() {
        const { database } = this.props;
        const { pass } = this.state;

        return (
            <div className={styles.lockadmin}>
                <div className={styles.lockadmin_content}>
                    <Switch
                        onChange={this.onClickSwitch}
                        value={!database.settings.locked}
                    />

                    <div
                        className={classNames(
                            fonts.h3,
                            fonts.bold,
                            database.settings.locked
                                ? fonts.red
                                : fonts.main_color_1
                        )}
                    >
                        {database.settings.locked
                            ? "Web uzamčen!"
                            : "Web je volně přístupný."}
                    </div>
                </div>
                {database.settings.locked && (
                    <div className={margin.small_top}>
                        <form onSubmit={this.onSubmit}>
                            <div className={styles.formprojects_input_title}>
                                Heslo
                            </div>
                            <input
                                className={styles.formprojects_input}
                                name="pass"
                                value={pass}
                                onChange={this.onChange}
                            />

                            <button
                                className={styles.formprojects_submit_button}
                                type="submit"
                            >
                                Uložit heslo
                            </button>
                        </form>
                    </div>
                )}
            </div>
        );
    }
}

export default LockAdmin;
