import React, { Component } from "react";

import styles from "./style.module.scss";

import { doUpdatePassword } from "../../utils/Firebase";

const INITIAL_STATE = {
    passwordOne: "",
    passwordTwo: "",
    error: null,
};

class PasswordChangeForm extends Component {
    state = { ...INITIAL_STATE };

    onSubmit = (event) => {
        const { passwordOne } = this.state;

        doUpdatePassword(passwordOne)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch((error) => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { passwordOne, passwordTwo, error } = this.state;

        const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

        return (
            <form className={styles.form} onSubmit={this.onSubmit}>
                <div className={styles.title}>Změna hesla</div>
                <input
                    className={styles.input}
                    name="passwordOne"
                    value={passwordOne}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Nové heslo"
                />
                <input
                    className={styles.input}
                    name="passwordTwo"
                    value={passwordTwo}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Znovu nové heslo"
                />
                <button
                    className={styles.input}
                    disabled={isInvalid}
                    type="submit"
                >
                    Změnit heslo
                </button>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

export default PasswordChangeForm;
