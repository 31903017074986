import React from "react";
import classNames from "classnames";
// SCSS
import styles from "./style.module.scss";
import fonts from "../../vars/fonts/fonts.module.scss";
// UI
import Section from "../../ui/Section";

const InfoTable = ({ id }) => (
    <Section
        id={id}
        noBottomPadding
        className={classNames(fonts.regular, fonts.center)}
    >
        <div className={classNames(fonts.medium, styles.infotable)}>
            <div
                className={classNames(
                    styles.infotable_column,
                    styles.infotable_column_1
                )}
            >
                <div>Ceny od:</div>
                <div>Typy:</div>
                <div>Plocha bytů:</div>
                <div>Dispozice:</div>
                <div>Lokalita:</div>
                <div>K nastěhování:</div>
                <div>PENB:</div>
            </div>
            <div
                className={classNames(
                    styles.infotable_column,
                    styles.infotable_column_2
                )}
            >
                <div>7 300 000 Kč</div>
                <div>Bytové jednotky</div>
                <div>56 m² – 240 m²</div>
                <div>2+kk – 5+kk</div>
                <div>ulice Horní, Brno – střed</div>
                <div>1Q/2023</div>
                <div>B</div>
            </div>
        </div>
    </Section>
);

export default InfoTable;
