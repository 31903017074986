import React, { Component } from "react";
import classNames from "classnames";
import { TweenLite } from "gsap";

// SCSS
import styles from "./style.module.scss";
import fonts from "../../vars/fonts/fonts.module.scss";
// ASSETS
import house from "./assets/house.png";
import a1P from "./assets/a/a1P.png";
import a2P from "./assets/a/a2P.png";
import a3P from "./assets/a/a3P.png";
import a4P from "./assets/a/a4P.png";
import a5P from "./assets/a/a5P.png";
import a6P from "./assets/a/a6P.png";

import b1P from "./assets/b/b1P.png";
import b2P from "./assets/b/b2P.png";
import b3P from "./assets/b/b3P.png";
import b4P from "./assets/b/b4P.png";
import b5P from "./assets/b/b5P.png";
import b6P from "./assets/b/b6P.png";
// COMPONENTS
import Pricelist from "../../components/Pricelist";
// UI
import Icon from "../../ui/Icon";
import Title from "../../ui/Title";
import Section from "../../ui/Section";
import HouseSwitch from "../../ui/HouseSwitch";

class HouseMap extends Component {
    imageRef = React.createRef();
    infoRef = React.createRef();
    containerRef = React.createRef();
    contentRef = React.createRef();
    controlsRef = React.createRef();

    state = {
        activeFlat: -1,
        activeLayer: 1,
        activeHouse: "",
        isOpen: false,
        disabledFlats: [],
        disabledFloors: {
            a: [],
            b: [],
            c: [],
        },
    };

    layers = [
        2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 6, 6, 7, 2, 2, 2, 3, 3, 3, 3, 4, 4,
        4, 4, 5, 5, 5, 6, 6, 7, 7,
    ];

    static getDerivedStateFromProps(props, state) {
        let flats = props.database ? props.database.flats : [];
        let floors = props.database ? props.database.floors : [];
        let disabledFlats = [];
        let a = [];
        let b = [];

        if (flats.length !== 0 && floors.length !== 0) {
            flats
                .filter((flat) => !flat.isAvailable)
                .forEach((flat) => {
                    disabledFlats.push(flat.id);
                });

            floors
                .filter((floor) => !floor.isAvailable)
                .forEach((floor) => {
                    if (floor.objekt === "a") {
                        a.push(floor.floor);
                    } else {
                        b.push(floor.floor);
                    }
                });
            return { disabledFlats, disabledFloors: { a, b } };
        }

        // Return null if the state hasn't changed
        return null;
    }

    renderSVGhouse = () => {
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <polygon
                    onClick={() =>
                        this.state.disabledFloors.a.includes(6)
                            ? null
                            : this.onChange(7, "a")
                    }
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.disabledFloors.a.includes(6) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    id="_6A"
                    points="851.45 428.75 831.78 424.27 831.78 413.17 213.96 258.21 213.96 294.44 851.45 445.96 851.45 428.75"
                />
                <polygon
                    onClick={() =>
                        this.state.disabledFloors.a.includes(5)
                            ? null
                            : this.onChange(6, "a")
                    }
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.disabledFloors.a.includes(5) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    id="_5A"
                    points="878.73 499.18 874.48 498.29 874.48 452 851.45 445.96 213.96 294.44 181.76 286.38 181.76 438.86 879.13 567.1 878.73 499.18"
                />
                <polygon
                    onClick={() =>
                        this.state.disabledFloors.a.includes(4)
                            ? null
                            : this.onChange(5, "a")
                    }
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.disabledFloors.a.includes(4) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    id="_4A"
                    points="879.13 567.1 181.76 438.86 181.76 563.44 879.68 662.44 879.13 567.1"
                />
                <polygon
                    onClick={() =>
                        this.state.disabledFloors.a.includes(3)
                            ? null
                            : this.onChange(4, "a")
                    }
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.disabledFloors.a.includes(3) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    id="_3A"
                    points="879.68 662.44 181.76 563.44 181.76 693.57 880.22 757.14 879.68 662.44"
                />
                <polygon
                    onClick={() =>
                        this.state.disabledFloors.a.includes(2)
                            ? null
                            : this.onChange(3, "a")
                    }
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.disabledFloors.a.includes(2) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    id="_2A"
                    points="880.75 847.7 880.22 757.14 181.76 693.57 181.76 820.7 874.04 855.58 874.04 849.49 880.75 847.7"
                />
                <polygon
                    onClick={() =>
                        this.state.disabledFloors.a.includes(1)
                            ? null
                            : this.onChange(2, "a")
                    }
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.disabledFloors.a.includes(1) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    id="_1A"
                    points="181.76 996.77 874.04 996.77 874.04 855.58 181.76 820.7 181.76 996.77"
                />
                <polygon
                    onClick={() =>
                        this.state.disabledFloors.b.includes(6)
                            ? null
                            : this.onChange(7, "b")
                    }
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.disabledFloors.b.includes(6) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    id="_6B"
                    points="1671.74 565.05 1671.74 552.47 1253.04 423.9 1010.55 590.6 1010.55 642.25 1001.83 647.95 1001.79 662.44 1346.88 469.67 1671.74 565.05"
                />
                <polygon
                    onClick={() =>
                        this.state.disabledFloors.b.includes(5)
                            ? null
                            : this.onChange(6, "b")
                    }
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.disabledFloors.b.includes(5) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    id="_5B"
                    points="1743.74 677.47 1743.01 590.37 1696.89 576.79 1696.89 572.09 1671.74 565.05 1346.88 469.67 1001.79 662.44 1001.6 734.46 1347.18 594.29 1743.74 677.47"
                />
                <polygon
                    onClick={() =>
                        this.state.disabledFloors.b.includes(4)
                            ? null
                            : this.onChange(5, "b")
                    }
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.disabledFloors.b.includes(4) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    id="_4B"
                    points="1744.4 757.05 1743.74 677.47 1347.18 594.29 1001.6 734.46 1001.42 798.32 1347.18 693.57 1744.4 757.05"
                />
                <polygon
                    onClick={() =>
                        this.state.disabledFloors.b.includes(3)
                            ? null
                            : this.onChange(4, "b")
                    }
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.disabledFloors.b.includes(3) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    id="_3B"
                    points="1347.18 693.57 1001.42 798.32 1001.25 863.61 1347.18 798.21 1709.86 829.37 1744.97 825.57 1744.4 757.05 1347.18 693.57"
                />
                <polygon
                    onClick={() =>
                        this.state.disabledFloors.b.includes(2)
                            ? null
                            : this.onChange(3, "b")
                    }
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.disabledFloors.b.includes(2) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    id="_2B"
                    points="1001.25 863.61 1001.15 898.83 1010.21 898.5 1010.21 922.53 1347.18 902.41 1696 910.09 1709.86 909.64 1709.86 829.37 1347.18 798.21 1001.25 863.61"
                />
                <polygon
                    onClick={() =>
                        this.state.disabledFloors.b.includes(1)
                            ? null
                            : this.onChange(2, "b")
                    }
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.disabledFloors.b.includes(1) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    id="_1B"
                    points="1010.21 922.53 1010.21 994.09 1348.97 1034.93 1696 1045.37 1696 910.09 1347.18 902.41 1010.21 922.53"
                />
            </svg>
        );
    };
    renderSVGa1 = () => {
        // A 1.NP
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <polygon
                    onClick={() => this.handleClickHousemap(0, "a")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 0 && styles.selected_polygon,
                        this.state.disabledFlats.includes(0) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="726.8 654.75 726.8 972.71 1043.42 972.71 1064.89 972.71 1064.89 1259.82 1459.33 1259.82 1459.33 654.75 726.8 654.75"
                />
            </svg>
        );
    };
    renderSVGa2 = () => {
        // A 2.NP
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <polygon
                    onClick={() => this.handleClickHousemap(1, "a")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 1 && styles.selected_polygon,
                        this.state.disabledFlats.includes(1) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="1273.51 556.81 1046.11 556.81 1046.11 719.82 960 719.82 960 1027.72 960 1027.72 960 1107.77 1264.12 1107.77 1264.12 1027.72 1273.51 1027.72 1273.51 556.81"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(2, "a")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 2 && styles.selected_polygon,
                        this.state.disabledFlats.includes(2) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="873.89 719.82 873.89 556.81 646.49 556.81 646.49 1027.72 736.19 1027.72 736.19 1107.77 960 1107.77 960 1027.72 960 1023.92 960 719.82 873.89 719.82"
                />{" "}
                <polygon
                    onClick={() => this.handleClickHousemap(3, "a")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 3 && styles.selected_polygon,
                        this.state.disabledFlats.includes(3) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="646.49 62.43 646.49 249.58 646.49 556.81 873.89 556.81 873.89 503.15 960 503.15 960 249.58 960 249.58 960 62.43 646.49 62.43"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(4, "a")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 4 && styles.selected_polygon,
                        this.state.disabledFlats.includes(4) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="1473.41 62.43 1473.41 549.43 1046.1 549.43 1046.1 503.15 960 503.15 960 62.43 1473.41 62.43"
                />
            </svg>
        );
    };
    renderSVGa3 = () => {
        // A 3.NP
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <polygon
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 5 && styles.selected_polygon,
                        this.state.disabledFlats.includes(5) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    onClick={() => this.handleClickHousemap(5, "a")}
                    points="960.99 719.15 960.99 1106.88 1263.9 1106.88 1263.9 1027.72 1274.63 1027.72 1274.63 559.5 1047.9 559.5 1047.9 719.49 960.99 719.15"
                />
                <polygon
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 6 && styles.selected_polygon,
                        this.state.disabledFlats.includes(6) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    onClick={() => this.handleClickHousemap(6, "a")}
                    points="960.99 719.15 960.99 1106.88 658.08 1106.88 658.08 1027.72 647.35 1027.72 647.35 559.5 874.08 559.5 874.08 719.49 960.99 719.15"
                />
                <polygon
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 7 && styles.selected_polygon,
                        this.state.disabledFlats.includes(7) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    onClick={() => this.handleClickHousemap(7, "a")}
                    points="874.08 559.5 874.08 502.7 960.99 502.7 960.99 169.98 657.63 169.98 657.63 249.14 647.35 249.14 647.35 559.5 874.08 559.5"
                />
                <polygon
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 8 && styles.selected_polygon,
                        this.state.disabledFlats.includes(8) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    onClick={() => this.handleClickHousemap(8, "a")}
                    points="1047.9 559.5 1047.9 502.7 960.99 502.7 960.99 169.98 1264.34 169.98 1264.34 249.14 1274.63 249.14 1274.63 559.5 1047.9 559.5"
                />
            </svg>
        );
    };
    renderSVGa4 = () => {
        // A 4.NP
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <polygon
                    onClick={() => this.handleClickHousemap(9, "a")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 9 && styles.selected_polygon,
                        this.state.disabledFlats.includes(9) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="1046.91 559.5 1046.91 719.49 960 719.15 873.09 719.49 873.09 559.5 646.36 559.5 646.36 1027.72 657.09 1027.72 657.09 1106.88 960 1106.88 1262.91 1106.88 1262.91 1027.72 1273.64 1027.72 1273.64 559.5 1046.91 559.5"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(10, "a")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 10 && styles.selected_polygon,
                        this.state.disabledFlats.includes(10) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="873.09 559.5 873.09 502.7 960 502.7 960 169.98 656.65 169.98 656.65 249.14 646.36 249.14 646.36 559.5 873.09 559.5"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(11, "a")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 11 && styles.selected_polygon,
                        this.state.disabledFlats.includes(11) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="1046.91 559.5 1046.91 502.7 960 502.7 960 169.98 1263.35 169.98 1263.35 249.14 1273.64 249.14 1273.64 559.5 1046.91 559.5"
                />
            </svg>
        );
    };
    renderSVGa5 = () => {
        // A 5.NP
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <polygon
                    onClick={() => this.handleClickHousemap(12, "a")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 12 && styles.selected_polygon,
                        this.state.disabledFlats.includes(12) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="1046.9,559.5 1046.9,719.5 960,719.2 873.1,719.5 873.1,559.5 646.4,559.5 646.4,1027.7 657.1,1027.7 657.1,1106.9 960,1106.9 1262.9,1106.9 1262.9,1027.7 1273.6,1027.7 1273.6,559.5 	"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(13, "a")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 13 && styles.selected_polygon,
                        this.state.disabledFlats.includes(13) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="1263.3,249.1 1263.3,170 960,170 656.7,170 656.7,249.1 646.4,249.1 646.4,559.5 873.1,559.5 873.1,502.7 960,502.7 1046.9,502.7 1046.9,559.5 1273.6,559.5 1273.6,249.1 	"
                />
            </svg>
        );
    };
    renderSVGa6 = () => {
        // A 6.NP
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <path
                    onClick={() => this.handleClickHousemap(14, "a")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 14 && styles.selected_polygon,
                        this.state.disabledFlats.includes(14) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    d="M1263.35,249.14V170H656.65v79.16H646.36V559.5h0V874.78h10.73V1014.3h605.82V874.78h10.73V559.5h0V249.14ZM1046.91,719.49,960,719.15l-86.91.34v-160h0V502.7h173.82v56.8h0Z"
                />
            </svg>
        );
    };

    renderSVGb1 = () => {
        // B 1.NP
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <polygon
                    onClick={() => this.handleClickHousemap(15, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 15 && styles.selected_polygon,
                        this.state.disabledFlats.includes(15) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="960 869.09 960 1265.53 1272.93 1265.53 1272.93 1260.46 1274.95 1260.46 1274.95 802.52 1046.34 802.52 1046.34 868.71 960 869.09"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(16, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 16 && styles.selected_polygon,
                        this.state.disabledFlats.includes(16) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="960 869.09 960 1265.53 647.07 1265.53 647.07 1260.46 414.82 1260.46 414.82 802.52 873.66 802.52 873.66 868.71 960 869.09"
                />

                <polygon
                    onClick={() => this.handleClickHousemap(17, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 17 && styles.selected_polygon,
                        this.state.disabledFlats.includes(17) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="647.97 650.62 647.97 493.65 657.36 493.65 657.36 378.27 1263.77 378.27 1263.77 494.99 1274.95 494.99 1274.95 802.52 1137.66 802.52 1137.66 764.66 1046.43 764.66 1046.43 652.96 647.97 650.62"
                />
            </svg>
        );
    };
    renderSVGb2 = () => {
        // B 2.NP
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <polygon
                    onClick={() => this.handleClickHousemap(18, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 18 && styles.selected_polygon,
                        this.state.disabledFlats.includes(18) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="960 626.92 960 1109.9 1185.06 1109.9 1185.06 1032.09 1273.61 1032.09 1273.61 565.2 1046.87 565.2 1046.87 626.92 960 626.92"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(19, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 19 && styles.selected_polygon,
                        this.state.disabledFlats.includes(19) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="960 626.92 960 1109.9 657.51 1109.9 657.51 1032.09 646.39 1032.09 646.39 565.2 873.13 565.2 873.13 626.92 960 626.92"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(20, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 20 && styles.selected_polygon,
                        this.state.disabledFlats.includes(20) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="873.13 565.2 646.39 565.2 646.39 252.61 656.46 252.61 656.46 172.11 960 172.11 960 411.59 873.46 411.59 873.13 565.2"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(21, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 21 && styles.selected_polygon,
                        this.state.disabledFlats.includes(21) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="1046.87 565.2 1273.61 565.2 1273.61 252.61 1263.55 252.61 1263.55 172.11 960 172.11 960 411.59 1046.55 411.59 1046.87 565.2"
                />
            </svg>
        );
    };
    renderSVGb3 = () => {
        // B 3.NP
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <polygon
                    onClick={() => this.handleClickHousemap(22, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 22 && styles.selected_polygon,
                        this.state.disabledFlats.includes(22) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="960 626.92 960 1109.23 1263.55 1109.23 1263.55 1031.41 1273.61 1031.41 1273.61 565.2 1046.87 565.2 1046.87 626.92 960 626.92"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(23, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 23 && styles.selected_polygon,
                        this.state.disabledFlats.includes(23) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="960 626.92 960 1109.9 657.51 1109.9 657.51 1032.09 646.39 1032.09 646.39 565.2 873.13 565.2 873.13 626.92 960 626.92"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(24, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 24 && styles.selected_polygon,
                        this.state.disabledFlats.includes(24) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="873.13 565.2 646.39 565.2 646.39 252.61 656.46 252.61 656.46 172.11 960 172.11 960 411.59 873.46 411.59 873.13 565.2"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(25, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 25 && styles.selected_polygon,
                        this.state.disabledFlats.includes(25) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="1046.87 565.2 1273.61 565.2 1273.61 252.61 1263.55 252.61 1263.55 172.11 960 172.11 960 411.59 1046.55 411.59 1046.87 565.2"
                />
            </svg>
        );
    };
    renderSVGb4 = () => {
        // B 4.NP
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <polygon
                    onClick={() => this.handleClickHousemap(26, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 26 && styles.selected_polygon,
                        this.state.disabledFlats.includes(26) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="960,626.9 960,1109.2 1263.6,1109.2 1263.6,1031.4 1273.6,1031.4 1273.6,565.2 1046.9,565.2 1046.9,626.9"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(27, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 27 && styles.selected_polygon,
                        this.state.disabledFlats.includes(27) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="960,626.9 960,1109.9 657.5,1109.9 657.5,1032.1 646.4,1032.1 646.4,565.2 873.1,565.2 873.1,626.9"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(28, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 28 && styles.selected_polygon,
                        this.state.disabledFlats.includes(28) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="1263.6,252.6 1263.6,172.1 960,172.1 656.5,172.1 656.5,252.6 646.4,252.6 646.4,565.2 873.1,565.2 873.5,411.6 960,411.6 1046.6,411.6 1046.9,565.2 1273.6,565.2 1273.6,252.6"
                />
            </svg>
        );
    };
    renderSVGb5 = () => {
        // B 5.NP
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <polygon
                    onClick={() => this.handleClickHousemap(29, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 29 && styles.selected_polygon,
                        this.state.disabledFlats.includes(29) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="960 626.32 960 1109.3 1263.92 1109.3 1263.92 1035.07 1280.91 1035.07 1280.91 563.71 1047.47 563.71 1047.47 626.43 960 626.32"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(30, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 30 && styles.selected_polygon,
                        this.state.disabledFlats.includes(30) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="960 626.32 960 1109.3 656.08 1109.3 656.08 1035.07 639.09 1035.07 639.09 563.71 872.53 563.71 872.53 626.43 960 626.32"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(31, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 31 && styles.selected_polygon,
                        this.state.disabledFlats.includes(31) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="1047.47 563.71 1280.91 563.71 1280.91 252.46 1263.92 252.46 1263.92 171.96 960 171.96 960 412.56 995.6 412.56 995.6 400.93 1047.58 400.93 1047.47 563.71"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(32, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 32 && styles.selected_polygon,
                        this.state.disabledFlats.includes(32) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="872.53 563.71 639.09 563.71 639.09 252.46 656.08 252.46 656.08 171.96 960 171.96 960 412.56 924.4 412.56 924.4 400.93 872.42 400.93 872.53 563.71"
                />
            </svg>
        );
    };
    renderSVGb6 = () => {
        // B 6.NP
        return (
            <svg
                className={styles.housemap_svg}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 1280"
            >
                <polygon
                    onClick={() => this.handleClickHousemap(31, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 31 && styles.selected_polygon,
                        this.state.disabledFlats.includes(31) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="960 626.32 1046.58 626.32 1046.58 476.95 1118.13 476.95 1118.13 559.24 1272.86 559.24 1272.86 879.44 1263.02 879.44 1263.02 1016.28 960 1016.28 960 626.32"
                />
                <polygon
                    onClick={() => this.handleClickHousemap(32, "b")}
                    className={classNames(
                        styles.housemap_svg_polygon,
                        this.state.activeFlat === 32 && styles.selected_polygon,
                        this.state.disabledFlats.includes(32) &&
                            styles.housemap_svg_polygon_disabled
                    )}
                    points="960 626.32 873.42 626.32 873.42 476.95 801.87 476.95 801.87 559.24 647.14 559.24 647.14 879.44 656.98 879.44 656.98 1016.28 960 1016.28 960 626.32"
                />
            </svg>
        );
    };

    openControls = () => {
        // controlsRef
        document.getElementById("controlsRef").style.height = "auto";
        const height = document
            .getElementById("controlsRef")
            .getBoundingClientRect().height;
        document.getElementById("controlsRef").style.height = 0;
        TweenLite.to(this.controlsRef.current, 0.4, {
            height,
            onComplete: () => {
                document.getElementById("controlsRef").style.height = "auto";
                TweenLite.to(this.controlsRef.current, 0.4, {
                    autoAlpha: 1,
                });
            },
        });
        this.setState({ isOpenControls: true });
    };
    closeControls = () => {
        TweenLite.to(this.controlsRef.current, 0.4, {
            autoAlpha: 0,
            onComplete: () => {
                TweenLite.to(this.controlsRef.current, 0.4, {
                    height: 0,
                });
            },
        });
        this.setState({ isOpenControls: false });
    };

    onChange = (activeLayer, activeHouse, activeFlat) => {
        const { isOpen, isOpenControls } = this.state;
        if (activeLayer === 1) {
            this.closeControls();
        } else if (activeLayer !== 1) {
            this.scrollTo("#controlsRef");
        }
        if (activeFlat === -1) {
            this.setState({ activeFlat });
        }
        TweenLite.to(this.contentRef.current, 0.4, {
            autoAlpha: 0,
            onComplete: () => {
                document.getElementById(
                    `housemap_layer_${
                        this.state.activeHouse && this.state.activeHouse
                    }${this.state.activeLayer}`
                ).style.display = "none";
                document.getElementById(
                    `housemap_layer_${
                        activeLayer === 1
                            ? activeLayer
                            : activeHouse + activeLayer
                    }`
                ).style.display = "block";
                this.setState({ activeHouse });
                const height =
                    this.contentRef.current.getBoundingClientRect().height;
                TweenLite.to(this.containerRef.current, 0.4, {
                    height,
                    onComplete: () => {
                        this.containerRef.current.style.height = "auto";
                    },
                });
                this.setState({ activeLayer }, () => {
                    TweenLite.to(this.contentRef.current, 0.4, {
                        autoAlpha: 1,
                        onComplete: () => {
                            if (!isOpenControls) {
                                this.openControls();
                            }
                        },
                    });
                });
            },
        });
        if (isOpen) {
            TweenLite.to(this.infoRef.current, 0.4, {
                autoAlpha: 0,
                height: 0,
                onComplete: () => {
                    if (activeLayer === 1) return;
                    this.setState({ isOpen: true });
                    document.getElementById("infoRef").style.height = "auto";
                    const height =
                        this.infoRef.current.getBoundingClientRect().height;
                    document.getElementById("infoRef").style.height = 0;

                    TweenLite.to(this.infoRef.current, 0.4, {
                        autoAlpha: 1,
                        height,
                        onComplete: () => {
                            document.getElementById("infoRef").style.height =
                                "auto";
                        },
                    });
                },
            });
        } else {
            this.setState({ isOpen: true }, () => {
                document.getElementById("infoRef").style.height = "auto";
                const height = document
                    .getElementById("infoRef")
                    .getBoundingClientRect().height;
                document.getElementById("infoRef").style.height = 0;
                TweenLite.to(this.infoRef.current, 0.4, {
                    autoAlpha: 1,
                    height,
                    onComplete: () => {
                        document.getElementById("infoRef").style.height =
                            "auto";
                    },
                });
            });
        }
    };

    handleClickHousemap = (flatId) => {
        !this.state.disabledFlats.includes(flatId) &&
            this.setState({ activeFlat: flatId });
    };

    closeInfo = () => {
        TweenLite.to(this.infoRef.current, 0.3, {
            autoAlpha: 0,
            height: 0,
            onComplete: () => {
                this.setState({ activeFlat: -1, isOpen: false });
            },
        });
    };

    scrollTo = (id, offsetY = 53) => {
        TweenLite.to(window, 1.5, {
            scrollTo: { y: id, offsetY, autoKill: false },
        });
    };

    handleClickPricelist = (flatId, activeHouse) => {
        this.scrollTo("#controlsRef");
        this.onChange(this.layers[flatId], activeHouse);
        this.handleClickHousemap(flatId, activeHouse);
    };

    onChangeHouse = (activeLayer, activeHouse) => {
        if (this.state.activeHouse === activeHouse) {
            return;
        } else {
            this.onChange(activeLayer, activeHouse, -1);
        }
    };

    openDropdown = () => {
        const height = this.contentRef.current.getBoundingClientRect().height;
        TweenLite.to(this.dropdownRef.current, this.props.time, {
            height,
            onComplete: () => {
                this.dropdownRef.current.style.height = "auto";
            },
        });

        this.setState({ isOpen: true });
    };

    scrollToContact = () => {
        TweenLite.to(window, 1, {
            scrollTo: { y: "#kontakt", autoKill: false },
        });
    };

    render() {
        const { activeLayer, activeFlat, activeHouse } = this.state;
        const { id, idPricelist, database } = this.props;

        let selectedFlats;
        const preselect = database.flats.filter(
            (flat) => flat.floor === activeLayer
        );

        if (activeHouse === "a") {
            selectedFlats = preselect.filter((flat) => flat.house === 1);
        } else {
            selectedFlats = preselect.filter((flat) => flat.house === 2);
        }

        return (
            <React.Fragment>
                <Section
                    noBottomPadding
                    id={id}
                    className={classNames(
                        fonts.regular,
                        styles.housemap_section
                    )}
                >
                    <Title text="přehled bytů" />
                    <div>
                        Každé patro obytného domu je řešeno jinak. Vyberte si
                        to, které vás právě zajímá, klikněte na něj myší a hned
                        se dozvíte víc!
                    </div>

                    <div
                        id="controlsRef"
                        ref={this.controlsRef}
                        className={classNames(
                            fonts.h3,
                            styles.housemap_controls
                        )}
                    >
                        <div
                            onClick={() => this.onChange(1, "", -1)}
                            className={classNames(
                                styles.housemap_controls_button_back
                            )}
                        >
                            <Icon className={styles.icon_back} name="reply" />
                            zpět
                        </div>
                        <div
                            className={styles.housemap_controls_selected_house}
                        >
                            {activeHouse === "a" ? "Dům A" : "Dům B"}
                        </div>

                        <div className={styles.housemap_controls_layers}>
                            <div
                                onClick={() =>
                                    this.onChange(
                                        this.state.activeLayer - 1,
                                        activeHouse
                                    )
                                }
                                className={classNames(
                                    styles.housemap_controls_button,
                                    activeLayer === 2 &&
                                        styles.housemap_controls_button_hidden
                                )}
                            >
                                <Icon
                                    className={styles.icon_arrow_left}
                                    name="send"
                                />
                                {`${activeLayer - 2}.NP`}
                            </div>

                            <div className={fonts.h2}>{`${
                                activeLayer - 1
                            }.NP`}</div>

                            <div
                                onClick={() =>
                                    this.onChange(
                                        this.state.activeLayer + 1,
                                        activeHouse
                                    )
                                }
                                className={classNames(
                                    styles.housemap_controls_button,
                                    activeLayer === 7 &&
                                        styles.housemap_controls_button_hidden
                                )}
                            >
                                {`${activeLayer}.NP`}
                                <Icon
                                    className={styles.icon_arrow_right}
                                    name="send"
                                />
                            </div>
                        </div>
                    </div>
                </Section>

                <div
                    ref={this.containerRef}
                    className={styles.housemap_container}
                >
                    <div
                        id="housemap"
                        ref={this.contentRef}
                        className={styles.housemap_content}
                    >
                        <div
                            className={styles.housemap_layer_house}
                            id="housemap_layer_1"
                        >
                            <img
                                src={house}
                                className={styles.housemap_image}
                                alt="house"
                            />
                            {this.renderSVGhouse()}
                        </div>

                        <div
                            className={styles.housemap_layer_floor}
                            id="housemap_layer_a2"
                        >
                            <img
                                src={a1P}
                                className={styles.housemap_image}
                                alt="a1P"
                            />
                            {this.renderSVGa1()}
                        </div>
                        <div
                            className={styles.housemap_layer_floor}
                            id="housemap_layer_a3"
                        >
                            <img
                                src={a2P}
                                className={styles.housemap_image}
                                alt="a2P"
                            />
                            {this.renderSVGa2()}
                        </div>
                        <div
                            className={styles.housemap_layer_floor}
                            id="housemap_layer_a4"
                        >
                            <img
                                src={a3P}
                                className={styles.housemap_image}
                                alt="a3P"
                            />
                            {this.renderSVGa3()}
                        </div>
                        <div
                            className={styles.housemap_layer_floor}
                            id="housemap_layer_a5"
                        >
                            <img
                                src={a4P}
                                className={styles.housemap_image}
                                alt="a4P"
                            />
                            {this.renderSVGa4()}
                        </div>
                        <div
                            className={styles.housemap_layer_floor}
                            id="housemap_layer_a6"
                        >
                            <img
                                src={a5P}
                                className={styles.housemap_image}
                                alt="a5P"
                            />
                            {this.renderSVGa5()}
                        </div>
                        <div
                            className={styles.housemap_layer_floor}
                            id="housemap_layer_a7"
                        >
                            <img
                                src={a6P}
                                className={styles.housemap_image}
                                alt="a6P"
                            />
                            {this.renderSVGa6()}
                        </div>

                        <div
                            className={styles.housemap_layer_floor}
                            id="housemap_layer_b2"
                        >
                            <img
                                src={b1P}
                                className={styles.housemap_image}
                                alt="b1P"
                            />
                            {this.renderSVGb1()}
                        </div>
                        <div
                            className={styles.housemap_layer_floor}
                            id="housemap_layer_b3"
                        >
                            <img
                                src={b2P}
                                className={styles.housemap_image}
                                alt="b2P"
                            />
                            {this.renderSVGb2()}
                        </div>
                        <div
                            className={styles.housemap_layer_floor}
                            id="housemap_layer_b4"
                        >
                            <img
                                src={b3P}
                                className={styles.housemap_image}
                                alt="b3P"
                            />
                            {this.renderSVGb3()}
                        </div>
                        <div
                            className={styles.housemap_layer_floor}
                            id="housemap_layer_b5"
                        >
                            <img
                                src={b4P}
                                className={styles.housemap_image}
                                alt="b4P"
                            />
                            {this.renderSVGb4()}
                        </div>
                        <div
                            className={styles.housemap_layer_floor}
                            id="housemap_layer_b6"
                        >
                            <img
                                src={b5P}
                                className={styles.housemap_image}
                                alt="b5P"
                            />
                            {this.renderSVGb5()}
                        </div>
                        <div
                            className={styles.housemap_layer_floor}
                            id="housemap_layer_b7"
                        >
                            <img
                                src={b6P}
                                className={styles.housemap_image}
                                alt="b6P"
                            />
                            {this.renderSVGb6()}
                        </div>
                    </div>
                </div>

                <div
                    id="infoRef"
                    ref={this.infoRef}
                    className={styles.housemap_info_container}
                >
                    <HouseSwitch
                        activeHouse={activeHouse}
                        onChange={(activeH) =>
                            this.onChangeHouse(activeLayer, activeH)
                        }
                    />
                    <div className={classNames(fonts.left, styles.pricelist)}>
                        {selectedFlats.map((flat, idx) => (
                            <div
                                onClick={() =>
                                    this.handleClickHousemap(flat.id)
                                }
                                key={`flat${idx}`}
                                className={classNames(
                                    styles.pricelist_item,
                                    activeFlat === flat.id &&
                                        styles.pricelist_item_active
                                )}
                            >
                                <div className={fonts.medium}>
                                    <div>Podlaží</div>
                                    <div>Označení</div>
                                    <div>Dispozice</div>
                                    <div>Výměra</div>
                                    <div>Lodžie</div>
                                    <div>Cena</div>
                                </div>

                                <div
                                    className={classNames(
                                        fonts.regular,
                                        styles.pricelist_item_center
                                    )}
                                >
                                    <div>{flat.podlazi}</div>
                                    <div>{flat.oznaceni}</div>
                                    <div>{flat.dispozice}</div>
                                    <div>{flat.vymera}</div>
                                    <div>{flat.lodzie}</div>
                                    <div>
                                        {flat.isRk ? (
                                            <div
                                                onClick={this.scrollToContact}
                                                className={styles.label}
                                            >
                                                Na dotaz v RK
                                            </div>
                                        ) : (
                                            flat.cena
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className={classNames(
                            fonts.center,
                            fonts.regular,
                            styles.pricelist_secondary,
                            styles.pricelist_secondary_desktop
                        )}
                    >
                        <div
                            className={classNames(
                                fonts.medium,
                                styles.pricelist_secondary_item,
                                styles.pricelist_secondary_item_heading
                            )}
                        >
                            <div>Podlaží</div>
                            <div>Označení</div>
                            <div>Dispozice</div>
                            <div>Výměra</div>
                            <div>Lodžie</div>
                            <div>Cena</div>
                        </div>
                        {selectedFlats
                            .filter((flat) => flat.isAvailable)
                            .map((flat, idx) => (
                                <div
                                    key={`flat${idx}`}
                                    onClick={() =>
                                        this.handleClickHousemap(flat.id)
                                    }
                                    className={classNames(
                                        styles.pricelist_secondary_item,
                                        activeFlat === flat.id &&
                                            styles.pricelist_item_active
                                    )}
                                >
                                    <div>{flat.podlazi}</div>
                                    <div>{flat.oznaceni}</div>
                                    <div>{flat.dispozice}</div>
                                    <div>{flat.vymera}</div>
                                    <div>{flat.lodzie}</div>
                                    <div>
                                        {flat.isRk ? (
                                            <div
                                                onClick={this.scrollToContact}
                                                className={styles.label}
                                            >
                                                Na dotaz v RK
                                            </div>
                                        ) : (
                                            flat.cena
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                <Pricelist
                    id={idPricelist}
                    database={database}
                    onClick={this.handleClickPricelist}
                />
            </React.Fragment>
        );
    }
}

export default HouseMap;
