import React, { Component } from "react";
import classNames from "classnames";
import { TweenLite } from "gsap";

import styles from "./style.module.scss";
import Icon from "../../ui/Icon";

class SectionDropdown extends Component {
    state = {};

    editRef = React.createRef();

    toggleDropdown = () => {
        if (this.state.isOpen) {
            this.closeDropdown();
        } else {
            this.openDropdown();
        }
    };

    openDropdown = () => {
        this.editRef.current.style.height = "auto";
        const height = this.editRef.current.getBoundingClientRect().height;
        this.editRef.current.style.height = "0";

        TweenLite.to(this.editRef.current, 0.2, {
            autoAlpha: 1,
            height,
            onComplete: () => {
                TweenLite.to(window, 0.6, {
                    scrollTo: {
                        y: `#${this.props.id}`,
                        offsetY: 68,
                        autoKill: false,
                    },
                });
                this.editRef.current.style.height = "auto";
            },
        });
        this.setState({ isOpen: true });
    };

    closeDropdown = () => {
        TweenLite.to(this.editRef.current, 0.2, {
            autoAlpha: 0,
            height: 0,
        });
        this.setState({ isOpen: false });
    };

    render() {
        const { id, title, subtitle, children, noBorder } = this.props;

        // auto open !this.state.isOpen && setTimeout(() => this.openDropdown(), 1000);

        return (
            <div
                id={id}
                className={classNames(
                    styles.editDropdown,
                    noBorder && styles.noBorder
                )}
            >
                <div className={styles.header} onClick={this.toggleDropdown}>
                    <div className={styles.editCardTitle}>{title}</div>
                    <div className={styles.editCardsubtitlee}>{subtitle}</div>
                    <div className={styles.icons}>
                        <Icon
                            name="arrow-down-sign-to-navigate"
                            className={styles.editCardIcon}
                        />
                    </div>
                </div>

                <div className={styles.dropdown} ref={this.editRef}>
                    <div className={styles.content}>{children}</div>
                </div>
            </div>
        );
    }
}

export default SectionDropdown;
