import React from "react";
import classNames from "classnames";
import Transition from "react-transition-group/Transition";
// SCSS
import styles from "./style.module.scss";
import fonts from "../../vars/fonts/fonts.module.scss";
import margin from "../../vars/margin/margin.module.scss";

class Lock extends React.Component {
    state = {
        pass: "",
        wrongPass: false,
        isLocked: false,
    };

    componentDidMount() {
        this.props.database.settings.locked &&
            this.setState({ isLocked: true });
    }

    onChange = (event) => {
        const value = event.target.value;
        this.setState({ init: false });
        this.setState({ [event.target.name]: value });
    };

    onSubmit = (event) => {
        event.preventDefault();

        this.setState(
            this.props.database.settings.pass === this.state.pass
                ? { isLocked: false }
                : { wrongPass: true }
        );
    };

    stopAppScroll = () => {
        document.body.style.overflow = "hidden";
    };

    startAppScroll = () => {
        document.body.style.overflow = "scroll";
    };

    render() {
        const { pass, wrongPass, isLocked } = this.state;

        return (
            <Transition
                in={isLocked}
                onEnter={this.stopAppScroll}
                onExit={this.startAppScroll}
                timeout={500}
            >
                <div
                    className={classNames(
                        styles.lock,
                        !isLocked && styles.lock_hidden
                    )}
                >
                    <div className={styles.lock_content}>
                        <div
                            className={classNames(
                                styles.lock_logo,
                                margin.medium_bottom
                            )}
                        />

                        <div
                            className={classNames(
                                fonts.h2,
                                fonts.white,
                                margin.medium_bottom
                            )}
                        >
                            Webová stránka je uzamčena!
                        </div>

                        <form onSubmit={this.onSubmit}>
                            <div className={fonts.button_small}>
                                Přístupové heslo
                            </div>

                            <div className={styles.lock_form_content}>
                                <input
                                    className={classNames(
                                        styles.lock_input,
                                        wrongPass && styles.lock_input_wrongpass
                                    )}
                                    name="pass"
                                    value={pass}
                                    onChange={this.onChange}
                                />
                                <button
                                    className={styles.lock_submit}
                                    type="submit"
                                >
                                    <div className={fonts.button_large}>
                                        Odemknout
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Transition>
        );
    }
}

export default Lock;
