import React from "react";
import classNames from "classnames";
import Transition from "react-transition-group/Transition";
// SCSS
import styles from "./style.module.scss";

// import Animation from "../Animation";

class Loader extends React.Component {
    stopAppScroll = () => {
        document.body.style.overflow = "hidden";
    };

    startAppScroll = () => {
        document.body.style.overflow = "scroll";
    };

    render() {
        const { loading } = this.props;
        return (
            <Transition
                in={loading}
                onEnter={this.stopAppScroll}
                onExit={this.startAppScroll}
                timeout={500}
            >
                <div
                    className={classNames(
                        styles.loader,
                        !loading && styles.loader_hidden
                    )}
                >
                    <div className={styles.loader_content}>
                        {/* <Animation /> */}
                    </div>
                </div>
            </Transition>
        );
    }
}

export default Loader;
