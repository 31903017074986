import React from "react";
import classNames from "classnames";
// SCSS
import styles from "./style.module.scss";
import fonts from "../../vars/fonts/fonts.module.scss";
import margin from "../../vars/margin/margin.module.scss";
// PDF
import pdf1 from "./assets/pdf/standardy-bj.pdf";
import pdf2 from "./assets/pdf/standardy-nove.pdf";
import pdf3 from "./assets/pdf/PENB-A.pdf";
import pdf4 from "./assets/pdf/PENB-B.pdf";
import pdf5 from "./assets/pdf/podlahy.pdf";
// COMPONENTS
import Title from "../../ui/Title";
import Section from "../../ui/Section";
import SvgIcon from "../../ui/SvgIcon";
import SectionDropdown from "../../ui/SectionDropdown";

const Standards = ({ id, database }) => {
    // const system =
    //     database.standards && database.standards.system
    //         ? database.standards.system
    //         : {};
    const flats =
        database.standards && database.standards.flats
            ? database.standards.flats
            : {};
    const house =
        database.standards && database.standards.house
            ? database.standards.house
            : {};

    return (
        <Section id={id} className={classNames(fonts.regular, fonts.center)}>
            <Title text="Standardy" />

            <div className={classNames(fonts.h3, margin.medium_bottom)}>
                Standardy vybavení
            </div>
            <div className={classNames(fonts.medium, margin.large_bottom)}>
                <div className={styles.standards_items}>
                    <a
                        className={styles.standards_item}
                        href={pdf5}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <SvgIcon name="pdf" className={styles.standards_svg} />
                        Katalog<br />podlahy
                    </a>

                    <a
                        className={styles.standards_item}
                        href={pdf1}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <SvgIcon name="pdf" className={styles.standards_svg} />
                        Katalog<br />koupelny
                    </a>

                    <a
                        className={styles.standards_item}
                        href={pdf2}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <SvgIcon name="pdf" className={styles.standards_svg} />
                        Standardy<br /><br />
                    </a>

                    <a
                        className={styles.standards_item}
                        href={pdf3}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <SvgIcon name="pdf" className={styles.standards_svg} />
                        PENB - A<br /><br />
                    </a>
                    <a
                        className={styles.standards_item}
                        href={pdf4}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <SvgIcon name="pdf" className={styles.standards_svg} />
                        PENB - B<br /><br />
                    </a>
                </div>
            </div>

            {/* <SectionDropdown initialHeight={200} time={1}>
        <div
            className={classNames(fonts.h3, styles.standards_subtitle)}
        >
            Konstrukční systém
        </div>
        {Object.values(system)
            .sort((a, b) => {
                return a.date - b.date;
            })
            .map((item, idx) => {
                return (
                    <div key={idx}>
                        <div
                            className={classNames(
                                styles.standards_title,
                                fonts.medium
                            )}
                        >
                            {item.name}
                        </div>
                        {item.desc}
                    </div>
                );
            })}
    </SectionDropdown> */}

            <SectionDropdown
                className={margin.large_bottom}
                initialHeight={200}
                time={1}
            >
                <div className={classNames(fonts.h3, margin.medium_bottom)}>
                    Standardy bytových jednotek
                </div>
                {Object.values(flats)
                    .sort((a, b) => {
                        return a.date - b.date;
                    })
                    .map((item, idx) => {
                        return (
                            <div className={fonts.center} key={idx}>
                                <div
                                    className={classNames(
                                        styles.standards_title,
                                        fonts.medium
                                    )}
                                >
                                    {item.name}
                                </div>
                                {item.desc}
                            </div>
                        );
                    })}
            </SectionDropdown>

            <SectionDropdown initialHeight={200} time={1}>
                <div className={classNames(fonts.h3, margin.medium_bottom)}>
                    Standardy společných prostor
                </div>
                {Object.values(house)
                    .sort((a, b) => {
                        return a.date - b.date;
                    })
                    .map((item, idx) => {
                        return (
                            <div className={fonts.center} key={idx}>
                                <div
                                    className={classNames(
                                        styles.standards_title,
                                        fonts.medium
                                    )}
                                >
                                    {item.name}
                                </div>
                                {item.desc}
                            </div>
                        );
                    })}
            </SectionDropdown>
        </Section>
    );
};

export default Standards;
