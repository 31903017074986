import React from "react";
import classNames from "classnames";
// SCSS
import styles from "./style.module.scss";
import fonts from "../../vars/fonts/fonts.module.scss";
import margin from "../../vars/margin/margin.module.scss";
// UI
import Title from "../../ui/Title";
import Section from "../../ui/Section";

const Footer = ({ id, database }) => {
    return (
        <div id={id} className={classNames(fonts.regular, styles.footer)}>
            <Section>
                <Title white text="Kontakt" />

                <div className={margin.medium_bottom}>
                    <div className={classNames(fonts.medium, fonts.white)}>
                        {database.contact.one.name}
                    </div>
					<div><a className={styles.footer_link} href={"tel:" + database.contact.one.phone}>{database.contact.one.phone}</a></div>
                    <div><a className={styles.footer_link} href={"mailto:" + database.contact.one.email}>{database.contact.one.email}</a></div>
                </div>

				<div className={margin.medium_bottom}>
					VL&Partneři s.r.o.<br />IČ: 03576353<br />sídlo: Jezuitská 6/1, 602 00 Brno
				</div>

                <div className={styles.footer_logo} />
            </Section>
        </div>
    );
};

export default Footer;
