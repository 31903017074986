import React, { Component } from "react";
import moment from "moment";
import "moment/locale/cs";

// COMPONENTS
import EditCard from "../EditCard";
import FormParking from "../FormParking";

class EditParking extends Component {
    static defaultProps = {
        database: {},
    };

    componentDidMount() {
        moment.locale("cs");
    }

    render() {
        const { database } = this.props;

        return (
            <React.Fragment>
                {database &&
                    database.parking.map((park, idx) => (
                        <EditCard
                            key={idx}
                            title={`Stání ${park.oznaceni}`}
                            subtitle={park.typ}
                        >
                            <FormParking
                                titleButton="Uložit změny"
                                data={park}
                            />
                        </EditCard>
                    ))}
            </React.Fragment>
        );
    }
}

export default EditParking;
