import React from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import AuthUserContext from "./context";

const withAuthentication = (Component) => {
    class WithAuthentication extends React.Component {
        state = {
            authUser: null,
        };

        componentDidMount() {
            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                this.setState({ authUser: user ? user : null });
            });
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }

    return WithAuthentication;
};

export default withAuthentication;
