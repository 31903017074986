import React from "react";
import classNames from "classnames";

import styles from "./style.module.scss";

const SvgIcon = ({ onClick, name, className }) => {
    switch (name) {
        case "bicycle":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="13.79"
                            transform="translate(-6.21 15) rotate(-45)"
                        />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm0,28.79A13.79,13.79,0,1,1,28.79,15,13.81,13.81,0,0,1,15,28.79Z" />
                        <path d="M9.35,15.27a3.82,3.82,0,1,0,3.82,3.82A3.82,3.82,0,0,0,9.35,15.27Zm0,6.8a3,3,0,1,1,3-3A3,3,0,0,1,9.35,22.07Z" />
                        <path d="M20.65,15.27a3.82,3.82,0,1,0,3.82,3.82A3.82,3.82,0,0,0,20.65,15.27Zm0,6.8a3,3,0,1,1,3-3A3,3,0,0,1,20.65,22.07Z" />
                        <polygon points="18.47 13.8 20.66 13.8 20.66 12.11 19.17 12.11 17.55 10.49 15.82 8.76 11.16 13.42 12.89 15.15 14.07 16.33 14.3 20.9 15.99 20.81 15.73 15.6 14.09 13.96 16.36 11.69 18.47 13.8" />
                        <path d="M17.93,9.69a1.3,1.3,0,1,0-1.3-1.3A1.3,1.3,0,0,0,17.93,9.69Z" />
                    </svg>
                </div>
            );
        case "bus":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="13.79"
                            transform="translate(-6.21 15) rotate(-45)"
                        />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM1.21,15A13.79,13.79,0,1,1,15,28.79,13.81,13.81,0,0,1,1.21,15Z" />
                        <path d="M17.93,6.42H12.07a3.91,3.91,0,0,0-3.9,3.9V18a3.9,3.9,0,0,0,3.9,3.9h5.86a3.89,3.89,0,0,0,3.9-3.9V10.32A3.9,3.9,0,0,0,17.93,6.42ZM11.11,20.11a1.25,1.25,0,1,1,0-2.49,1.25,1.25,0,0,1,0,2.49ZM10,14.43V10.35H20v4.08Zm8.85,5.68a1.25,1.25,0,1,1,1.25-1.25A1.24,1.24,0,0,1,18.89,20.11Z" />
                        <path d="M9.21,20.2h2.22a0,0,0,0,1,0,0v2.86a.52.52,0,0,1-.52.52H9.73a.52.52,0,0,1-.52-.52V20.2A0,0,0,0,1,9.21,20.2Z" />
                        <path d="M18.57,20.2h2.22a0,0,0,0,1,0,0v2.86a.52.52,0,0,1-.52.52H19.08a.52.52,0,0,1-.52-.52V20.2A0,0,0,0,1,18.57,20.2Z" />
                    </svg>
                </div>
            );
        case "castle":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="13.79"
                            transform="translate(-4.6 23.12) rotate(-67.5)"
                        />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM1.21,15A13.79,13.79,0,1,1,15,28.79,13.81,13.81,0,0,1,1.21,15Z" />
                        <path d="M22.89,13.69V12.48H7.11v1.21a3.37,3.37,0,0,1,0,6.51v2.49h5.43V17a2.46,2.46,0,0,1,4.92,0v5.74h5.43V20.2a3.37,3.37,0,0,1,0-6.51Zm0-6.38v4.32H7.11V7.31h2.3v3.22H12V7.31H13.7v3.22h2.6V7.31H18v3.22h2.61V7.31Z" />
                    </svg>
                </div>
            );
        case "education":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="13.79"
                            transform="translate(-6.21 15) rotate(-45)"
                        />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM1.21,15A13.79,13.79,0,1,1,15,28.79,13.81,13.81,0,0,1,1.21,15Z" />
                        <path d="M24.16,12.91v6H22.85v-6c-.08-.26-.58-.25-.88-.2l-1.6,1-5.55,3.38a.58.58,0,0,1-.62,0L8.44,13.65l-2.3-1.37a.6.6,0,0,1,0-1.06l8-4.12a.58.58,0,0,1,.57,0l8,4.12a.7.7,0,0,1,.24.21,1.59,1.59,0,0,1,.76.34,1.38,1.38,0,0,1,.46.79A1,1,0,0,1,24.16,12.91Z" />
                        <path d="M20.37,15.23v3.42a1.19,1.19,0,0,1-.55,1l-4.74,3.1a1.21,1.21,0,0,1-1.34,0L9,19.67a1.21,1.21,0,0,1-.55-1V15.17l5.09,3a1.84,1.84,0,0,0,1,.27,1.92,1.92,0,0,0,1-.27Z" />
                    </svg>
                </div>
            );
        case "expo":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="13.79"
                            transform="translate(-6.21 15) rotate(-45)"
                        />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM1.21,15A13.79,13.79,0,1,1,15,28.79,13.81,13.81,0,0,1,1.21,15Z" />
                        <rect x="6.91" y="21.75" width="16.18" height="1" />
                        <rect x="6.91" y="13.01" width="3.63" height="6.94" />
                        <rect x="13.18" y="13.01" width="3.63" height="6.94" />
                        <rect x="19.46" y="13.01" width="3.63" height="6.94" />
                        <polygon points="8.22 13.01 9.22 13.01 9.22 10.03 14.5 10.03 14.5 13.01 15.5 13.01 15.5 10.03 20.77 10.03 20.77 13.01 21.77 13.01 21.77 10.03 24.08 10.03 15 5.25 5.92 10.03 8.22 10.03 8.22 13.01" />
                    </svg>
                </div>
            );
        case "healthcare":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="13.79"
                            transform="translate(-6.21 15) rotate(-45)"
                        />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM1.21,15A13.79,13.79,0,1,1,15,28.79,13.81,13.81,0,0,1,1.21,15Z" />
                        <polygon points="23.48 12.58 17.42 12.58 17.42 6.52 12.58 6.52 12.58 12.58 6.52 12.58 6.52 17.42 12.58 17.42 12.58 23.48 17.42 23.48 17.42 17.42 23.48 17.42 23.48 12.58" />
                    </svg>
                </div>
            );
        case "point":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circl cx="15" cy="15" r="13.79" />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM1.21,15A13.79,13.79,0,1,1,15,28.79,13.81,13.81,0,0,1,1.21,15Z" />
                        <circle cx="15" cy="15" r="3.8" />
                        <path d="M15,21.61A6.61,6.61,0,1,1,21.61,15,6.62,6.62,0,0,1,15,21.61Zm0-12A5.4,5.4,0,1,0,20.4,15,5.4,5.4,0,0,0,15,9.6Z" />
                        <path d="M15,23.52A8.52,8.52,0,1,1,23.52,15,8.53,8.53,0,0,1,15,23.52ZM15,7.09A7.91,7.91,0,1,0,22.91,15,7.92,7.92,0,0,0,15,7.09Z" />
                    </svg>
                </div>
            );
        case "shop":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="13.79"
                            transform="translate(-3.05 3.85) rotate(-13.28)"
                        />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm0,28.79A13.79,13.79,0,1,1,28.79,15,13.81,13.81,0,0,1,15,28.79Z" />
                        <path d="M24.77,16.88,23,9.82H7L5.23,16.88H7.06V23H17.13V16.88h4.41V23h1.73V16.88Zm-9.46,4.33H8.88V16.88h6.43Z" />
                        <rect x="7.06" y="7.03" width="15.87" height="1.62" />
                    </svg>
                </div>
            );
        case "swimming":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="13.79"
                            transform="translate(-6.21 15) rotate(-45)"
                        />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM1.21,15A13.79,13.79,0,1,1,15,28.79,13.81,13.81,0,0,1,1.21,15Z" />
                        <path d="M22.43,17.28a2.22,2.22,0,0,0-1.82-1.16,2.2,2.2,0,0,0-1.81,1.16c-.36.48-.58.75-1,.75s-.64-.27-1-.75A2.19,2.19,0,0,0,15,16.12a2.2,2.2,0,0,0-1.81,1.16c-.35.48-.57.75-1,.75s-.64-.27-1-.75a2.2,2.2,0,0,0-1.81-1.16,2.2,2.2,0,0,0-1.81,1.16c-.35.48-.57.75-1,.75V19a2.2,2.2,0,0,0,1.81-1.15c.35-.48.57-.75,1-.75s.64.27,1,.75A2.2,2.2,0,0,0,12.19,19,2.2,2.2,0,0,0,14,17.83c.35-.48.57-.75,1-.75s.64.27,1,.75A2.2,2.2,0,0,0,17.8,19a2.2,2.2,0,0,0,1.81-1.15c.36-.48.58-.75,1-.75s.64.27,1,.75A2.2,2.2,0,0,0,23.42,19V18C23,18,22.78,17.76,22.43,17.28Z" />
                        <path d="M22.43,21.21a2.2,2.2,0,0,0-1.82-1.16,2.18,2.18,0,0,0-1.81,1.16c-.36.48-.58.75-1,.75s-.64-.27-1-.75A2.17,2.17,0,0,0,15,20.05a2.18,2.18,0,0,0-1.81,1.16c-.35.48-.57.75-1,.75s-.64-.27-1-.75a2.18,2.18,0,0,0-1.81-1.16,2.18,2.18,0,0,0-1.81,1.16c-.35.48-.57.75-1,.75v.95a2.18,2.18,0,0,0,1.81-1.16c.35-.48.57-.75,1-.75s.64.27,1,.75a2.19,2.19,0,0,0,1.82,1.16A2.18,2.18,0,0,0,14,21.75c.35-.47.57-.75,1-.75s.64.28,1,.75a2.18,2.18,0,0,0,1.81,1.16,2.18,2.18,0,0,0,1.81-1.16c.36-.47.58-.75,1-.75s.64.28,1,.75a2.18,2.18,0,0,0,1.81,1.16V22C23,22,22.78,21.69,22.43,21.21Z" />
                        <path d="M8,9.31h2.25l2.36,2.16-2.21,2.21a1,1,0,0,0,0,1.42,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29L15,13.66l1.8,1.66a1,1,0,0,0,.68.26,1,1,0,0,0,.73-.32,1,1,0,0,0-.06-1.42L11,7.31H8a1,1,0,0,0,0,2Z" />
                        <circle cx="18.48" cy="9.41" r="2.32" />
                    </svg>
                </div>
            );
        case "train":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="13.79"
                            transform="translate(-6.21 15) rotate(-45)"
                        />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM1.21,15A13.79,13.79,0,1,1,15,28.79,13.81,13.81,0,0,1,1.21,15Z" />
                        <path d="M17.93,6.42H12.07a3.91,3.91,0,0,0-3.9,3.9V18a3.88,3.88,0,0,0,3.45,3.85L9.74,23.73H20.26l-1.88-1.89A3.88,3.88,0,0,0,21.83,18V10.32A3.9,3.9,0,0,0,17.93,6.42Zm2,3.93v4.08H16.05V10.35Zm-9.92,0H14v4.08H10Zm-.18,8.51a1.25,1.25,0,1,1,1.25,1.25A1.24,1.24,0,0,1,9.86,18.86Zm9,1.25a1.25,1.25,0,1,1,1.25-1.25A1.24,1.24,0,0,1,18.89,20.11Z" />
                    </svg>
                </div>
            );
        case "tram":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="13.79"
                            transform="translate(-6.21 15) rotate(-45)"
                        />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm0,28.79A13.79,13.79,0,1,1,28.79,15,13.81,13.81,0,0,1,15,28.79Z" />
                        <path d="M20.47,20.7a3.66,3.66,0,0,0,2-3.24V13.39a3.66,3.66,0,0,0-3.66-3.67h-3V8h1v.86h1.75V6.34H16.77V7.2h-1V6.7a.46.46,0,0,0-.46-.47h-.73a.46.46,0,0,0-.46.47v.5h-.93V6.34H11.49V8.82h1.74V8h.93V9.72H11.24a3.66,3.66,0,0,0-3.66,3.67v4.07a3.66,3.66,0,0,0,1.93,3.23v1.16L8.18,23.18l.59.59,1.33-1.33h9.77l1.33,1.33.59-.59-1.33-1.33Zm-.89-1.29a.86.86,0,1,1,.86-.85A.85.85,0,0,1,19.58,19.41Zm-10-6.93h3.94v-1h3v1h3.94v3.25H9.58Zm.84,5.22a.86.86,0,1,1-.86.86A.85.85,0,0,1,10.42,17.7Z" />
                    </svg>
                </div>
            );
        case "tree":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="13.79"
                            transform="translate(-2.21 2.6) rotate(-9.22)"
                        />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM1.21,15A13.79,13.79,0,1,1,15,28.79,13.81,13.81,0,0,1,1.21,15Z" />
                        <path d="M12.83,18.59s-6.48,2.73-6.14-7c-.34-.86,1-3.58,3.07-2.9C11.13,6,14.2,4.26,15.9,7c3.76-.68,5.8.68,5.8,3.07,2.73,1.71,1.37,2.9.94,3.5.34,1.45.77,6.14-5,4.69v6.14H12.9Z" />
                    </svg>
                </div>
            );
        case "tree2":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="13.79"
                            transform="translate(-6.21 15) rotate(-45)"
                        />
                        <path d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM1.21,15A13.79,13.79,0,1,1,15,28.79,13.81,13.81,0,0,1,1.21,15Z" />
                        <polygon points="18.96 15 21.99 15 15 6.47 8.01 15 11.04 15 6.63 20.45 12.74 20.45 12.74 23.53 15 23.53 17.26 23.53 17.26 20.45 23.37 20.45 18.96 15" />
                    </svg>
                </div>
            );
        case "pdf":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 40 40"
                    >
                        <path d="M11.78,25.73c0-.66-.46-1.06-1.27-1.06a2.89,2.89,0,0,0-.68.07v2.13a2.69,2.69,0,0,0,.55,0C11.25,26.91,11.78,26.47,11.78,25.73Z" />
                        <path d="M16.82,24.7a3.6,3.6,0,0,0-.74.06v4.72a3.21,3.21,0,0,0,.57,0A2.24,2.24,0,0,0,19.09,27,2.07,2.07,0,0,0,16.82,24.7Z" />
                        <path d="M26.17,0H10.11A4.26,4.26,0,0,0,5.85,4.26V20H5.43a1.72,1.72,0,0,0-1.71,1.72v10.4a1.71,1.71,0,0,0,1.71,1.71h.42v1.91A4.26,4.26,0,0,0,10.11,40H32a4.26,4.26,0,0,0,4.25-4.26V10.08Zm-18,23.59a14,14,0,0,1,2.2-.15,3.5,3.5,0,0,1,2.21.58,2.13,2.13,0,0,1,.77,1.67,2.31,2.31,0,0,1-.66,1.71,3.35,3.35,0,0,1-2.35.76,4.5,4.5,0,0,1-.56,0v2.58H8.22ZM32,37.39H10.11a1.65,1.65,0,0,1-1.65-1.65V33.83H28.89a1.71,1.71,0,0,0,1.72-1.71V21.72A1.72,1.72,0,0,0,28.89,20H8.46V4.26a1.65,1.65,0,0,1,1.65-1.65l15.09,0V8.17a3,3,0,0,0,2.95,2.95h5.46l.06,24.63A1.64,1.64,0,0,1,32,37.39ZM14.44,30.68V23.59a15.45,15.45,0,0,1,2.21-.15,4.66,4.66,0,0,1,3,.77,3.2,3.2,0,0,1,1.22,2.72,3.67,3.67,0,0,1-1.2,2.93,5.18,5.18,0,0,1-3.33.93A14.74,14.74,0,0,1,14.44,30.68Zm11.68-4.2v1.33H23.53v2.9H21.9V23.5h4.41v1.34H23.53v1.64Z" />
                    </svg>
                </div>
            );
        case "lupa":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 40 40"
                    >
                        <path d="M24.2,0C15.6,0,8.61,7.53,8.61,16.79a17.57,17.57,0,0,0,2.44,9L.91,35.91a2.4,2.4,0,0,0,3.4,3.39l9.76-9.77a14.88,14.88,0,0,0,10.13,4c8.59,0,15.59-7.53,15.59-16.79S32.79,0,24.2,0Zm0,28.78c-5.95,0-10.8-5.38-10.8-12s4.85-12,10.8-12S35,10.18,35,16.79,30.15,28.78,24.2,28.78Z" />
                    </svg>
                </div>
            );
        case "house-a":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 85 85"
                    >
                        <path d="M44.4,37.06H40.55l2-5.43Z" />
                        <path d="M72.11,10.32l-3.46-.06V7.9H16.35v2.36l-3.46.06v60.6H72.11ZM46.84,44.07l-.92-2.62H39l-1,2.62H31.7l7.84-18.56h6.29l7.55,18.56Z" />
                        <rect x="23.83" y="74.19" width="37.41" height="2.91" />
                    </svg>
                </div>
            );
        case "house-b":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 85 85"
                    >
                        <path d="M42.9,32.66H40V30H42.9a1.38,1.38,0,0,1,1,.35,1.17,1.17,0,0,1,.39.94,1.25,1.25,0,0,1-.39,1A1.35,1.35,0,0,1,42.9,32.66Z" />
                        <path d="M44.78,38.08a1.3,1.3,0,0,1-.52,1.08,2.07,2.07,0,0,1-1.36.41H40V36.74H42.9a2.33,2.33,0,0,1,1.36.37A1.12,1.12,0,0,1,44.78,38.08Z" />
                        <path d="M12.9,70.92H72.11V10.32l-3.46-.05V7.9H16.35v2.36l-3.46.06ZM50.23,41.6a5.72,5.72,0,0,1-2.61,1.83,11,11,0,0,1-3.93.64H33.84V25.51h9.4a11.42,11.42,0,0,1,3.89.59,5.66,5.66,0,0,1,2.57,1.64,3.7,3.7,0,0,1,.9,2.46,3.87,3.87,0,0,1-.85,2.46,4.4,4.4,0,0,1-2.27,1.51,4.49,4.49,0,0,1,2.67,1.65,4.75,4.75,0,0,1,1,3A4.33,4.33,0,0,1,50.23,41.6Z" />
                        <rect x="20.91" y="74.19" width="37.41" height="2.91" />
                    </svg>
                </div>
            );
        case "checked":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                    </svg>
                </div>
            );
        case "mail":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        height="20"
                        width="20"
                        viewBox="0 0 88 60"
                    >
                        <path
                            d="M44.1-0.1c10.6,0,21.3,0.2,31.9,0C84.2-0.3,88.3,2.8,88,12c-0.4,12.7-0.1,25.4-0.1,38.1c0,6.7-3.1,10-9.8,10
			c-22.8,0.1-45.5,0.1-68.3,0C3.2,60,0,56.7,0,50.1C0,36.7,0,23.4,0,10C0,3.2,3.2,0,10.1,0C21.4-0.1,32.8-0.1,44.1-0.1z M44,5.8
			c-11.3,0-22.6,0.1-34,0c-3.1,0-4.6,0.9-4.6,4.3c0.1,13.4,0.1,26.8,0,40.1c0,2.9,1.2,4.1,4.1,4.1c22.9-0.1,45.8-0.1,68.6,0
			c3.1,0,4.2-1.2,4.2-4.3c-0.1-13.3-0.1-26.5,0-39.8c0-3.3-1.2-4.5-4.4-4.4C66.7,5.9,55.3,5.8,44,5.8z"
                        />
                        <path
                            d="M13.7,11c1.4,0.2,2.3,1.1,3.3,2c7.9,7,15.9,13.8,23.6,20.9c2.8,2.6,4.3,2.3,6.9-0.1
			c7.7-7.1,15.7-14,23.6-20.9c1.6-1.4,3.3-3.1,5.2-0.8c1.8,2.2,0,3.7-1.6,5.1c-9.1,8-18.2,16-27.2,24.1c-2.4,2.2-4.3,2.4-6.8,0.1
			C31.5,33.1,22.2,25,12.9,16.9c-1.3-1.1-2.4-2.3-1.7-4.1C11.6,11.6,12.6,11.1,13.7,11z"
                        />
                    </svg>
                </div>
            );
        case "phone":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        height="20"
                        width="20"
                        viewBox="0 0 88 92"
                    >
                        <path
                            d="M49.3,0.7c1.9,0,3.8,0.1,5.6,0.5c1.2,0.3,2.3,0.5,3.5,0.8C75.2,6.6,87.7,22.5,88,40c0,2.2-0.3,4.3-2.8,4.5
			c-2.7,0.2-3.5-1.6-3.7-4.1c-1-13.7-7.4-23.8-19.8-29.9C57.5,8.3,53,7.7,48.4,7.2c-1.7-0.2-3.2-0.4-4-2.3c-0.8-2,0.6-3,1.7-4.3
			H49.3z"
                        />
                        <path
                            d="M59.3,91.3c-4.6-0.3-10-3.4-15.3-6.3C24.3,74.6,10.5,58.7,1.8,38.3c-4.7-11,0.1-21.1,11.5-23.7
			c3-0.7,5.7,0,7.9,1.9c4.9,4.4,8.8,9.5,11.5,15.5c2.1,4.6,0.4,9.3-3.8,11.8c-1.8,1.1-3.4,1.1-4.7-0.6c-1.2-1.6-1-3.4,0.5-4.4
			c3.3-2.1,2.2-4.5,0.7-6.8c-2.1-3.4-4.4-6.6-7.3-9.5c-1.7-1.7-3.3-1.8-5.4-0.9C7.2,24,5,28.6,7.3,34.3c7.2,17.6,18.4,32,34.7,42
			c4.7,2.9,9.6,5.5,14.8,7.5c5.9,2.3,10.5,0.2,12.9-5.4c0.8-2,0.8-3.5-0.8-5.1c-3.4-3.3-7.2-6.1-11.3-8.3c-1.9-1-3.2-0.9-4.5,1
			c-1.3,1.8-3.1,2.8-5.1,1.1c-2-1.8-1.2-3.8,0.3-5.6c3-3.8,6.8-4.8,11.3-2.7c5.8,2.7,10.9,6.6,15.2,11.3c2.1,2.3,2.6,5.1,2,8.2
			C75,85.9,68.6,91.4,59.3,91.3z"
                        />
                        <path
                            d="M69.4,40c0.3,2.3-0.4,4.2-2.8,4.5c-2.5,0.3-3.3-1.5-3.7-3.7c-2-10-4.7-12.7-15-14.8c-2-0.4-3.7-1.3-3.5-3.6
			c0.2-2.2,1.8-3.2,4-3.1C59.2,19.5,68.9,29,69.4,40z"
                        />
                    </svg>
                </div>
            );
        case "musicon":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 30"
                        x="0px"
                        y="0px"
                    >
                        <path d="M2,9H6L10,5H12V20H10L6,16H2V9M3,15H6.41L10.41,19H11V6H10.41L6.41,10H3V15M13.96,15.33L16.79,12.5L13.96,9.67L14.67,8.96L17.5,11.79L20.33,8.96L21.04,9.67L18.21,12.5L21.04,15.33L20.33,16.04L17.5,13.21L14.67,16.04L13.96,15.33Z" />
                    </svg>
                </div>
            );
        case "musicoff":
            return (
                <div
                    onClick={onClick}
                    className={classNames(styles.svgicon, className)}
                >
                    <svg
                        className={styles.svgicon_svg}
                        xmlns="http://www.w3.org/2000/svg"
                        // height="20"
                        // width="20"
                        viewBox="0 0 24 30"
                    >
                        <path d="M21,12.5C21,16.47 17.91,19.73 14,20V19C17.36,18.73 20,15.92 20,12.5C20,9.08 17.36,6.27 14,6V5C17.91,5.27 21,8.53 21,12.5M18,12.5C18,14.82 16.25,16.72 14,16.97V15.96C15.7,15.72 17,14.26 17,12.5C17,10.74 15.7,9.28 14,9.04V8.03C16.25,8.28 18,10.18 18,12.5M15,12.5C15,13.15 14.58,13.71 14,13.91V11.09C14.58,11.29 15,11.85 15,12.5M2,9H6L10,5H12V20H10L6,16H2V9M3,15H6.41L10.41,19H11V6H10.41L6.41,10H3V15Z" />
                    </svg>
                </div>
            );

        default:
            return <div>default fallback</div>;
    }
};

export default SvgIcon;
