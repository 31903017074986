import React from "react";

import styles from "./style.module.scss";

const Link = ({ name, onClick }) => (
    <div onClick={onClick} className={styles.link}>
        {name}
    </div>
);

export default Link;
