import React, { memo } from "react";
import classNames from "classnames";
// SCSS
import styles from "./style.module.scss";
import fonts from "../../vars/fonts/fonts.module.scss";
import margin from "../../vars/margin/margin.module.scss";
// UI
import Title from "../../ui/Title";
import Section from "../../ui/Section";
import SvgIcon from "../../ui/SvgIcon";

const Location = ({ id, database }) => {
    const pins = database.pins ? database.pins : {};
    return (
        <React.Fragment>
            <Section noBottomPadding>
                <Title text="Lokalita" />
            </Section>

            <div id={id} className={styles.location_image}></div>

            <Section
                noTopPadding
                noBottomPadding
                className={classNames(
                    fonts.regular,
                    styles.location_section,
                    margin.medium_top
                )}
            >
                {Object.values(pins)
                    .sort((a, b) => {
                        return a.date - b.date;
                    })
                    .map((pin, idx) => {
                        return (
                            <div key={idx} className={styles.location_pin}>
                                <SvgIcon
                                    name={pin.flag}
                                    className={styles.location_pin_icon}
                                />
                                {pin.title}
                            </div>
                        );
                    })}
            </Section>
        </React.Fragment>
    );
};

export default memo(Location);
