import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// SCSS
import styles from "./style.module.scss";
import * as ROUTES from "../../constants/routes";

import { doSignIn } from "../../utils/Firebase";

import AdminFormContainer from "../../ui-admin/AdminFormContainer";
import { PasswordForgetLink } from "../PasswordForget";

const SignInPage = () => (
    <AdminFormContainer>
        <div className={styles.page}>
            <div className={styles.title}>Přihlášení do administrace</div>
            <SignInForm />
            <PasswordForgetLink />
        </div>
    </AdminFormContainer>
);

const INITIAL_STATE = {
    email: "",
    password: "",
    error: null,
};

class SignInFormBase extends Component {
    state = { ...INITIAL_STATE };

    onSubmit = (event) => {
        const { email, password } = this.state;
        event.preventDefault();
        return doSignIn(email, password)
            .then((userCredential) => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.ADMIN);

                // const user = userCredential.user;
                // console.log(user);
            })
            .catch((error) => {
                this.setState({ error: error.message });
            });
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;

        const isInvalid = password === "" || email === "";

        return (
            <form className={styles.form} onSubmit={this.onSubmit}>
                <input
                    className={styles.input}
                    name="email"
                    value={email}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Email Address"
                />
                <input
                    className={styles.input}
                    name="password"
                    value={password}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Password"
                />
                <button
                    className={styles.input}
                    disabled={isInvalid}
                    type="submit"
                >
                    Přihlásit se
                </button>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const SignInForm = withRouter(SignInFormBase);

export default SignInPage;

export { SignInForm };
