import React from "react";

const isTouch = () => {
    if (!window.navigator) return false;
    return (
        "ontouchstart" in window ||
        navigator.MaxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
};

class NoTouch extends React.PureComponent {
    componentDidMount = () => {
        if (!isTouch()) {
            document.body.classList.add("no-touch");
        }
    };

    render() {
        return null;
    }
}

export default NoTouch;
