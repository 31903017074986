import React, { Component } from "react";
import classNames from "classnames";
// SCSS
import styles from "./style.module.scss";
// UI
import SvgIcon from "../../ui/SvgIcon";

class HouseSwitch extends Component {
    constructor(props) {
        super(props);

        this.underlineRef = React.createRef();
    }

    render() {
        const { activeHouse = "a", onChange } = this.props;

        return (
            <div className={styles.houseswitch}>
                <SvgIcon
                    onClick={() => onChange("a")}
                    name="house-a"
                    className={classNames(
                        styles.houseswitch_svg,
                        activeHouse === "a" && styles.houseswitch_svg_active
                    )}
                />
                <SvgIcon
                    onClick={() => onChange("b")}
                    name="house-b"
                    className={classNames(
                        styles.houseswitch_svg,
                        activeHouse === "b" && styles.houseswitch_svg_active
                    )}
                />
            </div>
        );
    }
}

export default HouseSwitch;
