import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
// SCSS
import styles from "./style.module.scss";
// UI
import Title from "../../ui/Title";
import Section from "../../ui/Section";
import SectionDropdown from "../../ui/SectionDropdown";

const Vizualization = ({ id }) => {
    const images = [
        {
            src: require("./assets/viz2.jpg"),
            width: 1.5,
            height: 1,
        },
        {
            src: require("./assets/viz3.jpg"),
            width: 1.5,
            height: 1,
        },
        {
            src: require("./assets/viz1.jpg"),
            width: 4,
            height: 2,
        },
        {
            src: require("./assets/3kk/3kk1.jpg"),
            width: 1.7,
            height: 1,
        },
        {
            src: require("./assets/3kk/3kk2.jpg"),
            width: 1.3,
            height: 1,
        },
        {
            src: require("./assets/3kk/3kk3.jpg"),
            width: 1.3,
            height: 1,
        },
        {
            src: require("./assets/3kk/3kk4.jpg"),
            width: 1.7,
            height: 1,
        },
        {
            src: require("./assets/3kk/3kk5.jpg"),
            width: 1.7,
            height: 1,
        },
        {
            src: require("./assets/3kk/3kk6.jpg"),
            width: 1.3,
            height: 1,
        },
        {
            src: require("./assets/3kk/3kk7.jpg"),
            width: 4,
            height: 2,
        },
        {
            src: require("./assets/5kk/5kk1.jpg"),
            width: 1.5,
            height: 1,
        },
        {
            src: require("./assets/5kk/5kk2.jpg"),
            width: 1.5,
            height: 1,
        },
        {
            src: require("./assets/5kk/5kk3.jpg"),
            width: 4,
            height: 2,
        },
        {
            src: require("./assets/5kk/5kk4.jpg"),
            width: 1.7,
            height: 1,
        },
        {
            src: require("./assets/5kk/5kk5.jpg"),
            width: 1.3,
            height: 1,
        },
        {
            src: require("./assets/5kk/5kk6.jpg"),
            width: 1.3,
            height: 1,
        },
        {
            src: require("./assets/5kk/5kk7.jpg"),
            width: 1.7,
            height: 1,
        },
        {
            src: require("./assets/5kk/5kk8.jpg"),
            width: 4,
            height: 2,
        },
        {
            src: require("./assets/5kk/5kk9.jpg"),
            width: 1.5,
            height: 1,
        },
        {
            src: require("./assets/5kk/5kk10.jpg"),
            width: 1.5,
            height: 1,
        },
        {
            src: require("./assets/5kk/5kk11.jpg"),
            width: 4,
            height: 2,
        },
    ];

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <div className={styles.gallery}>
            <Section id={id}>
                <Title text="Vizualizace" />

                <SectionDropdown grey initialHeight={500} time={1}>
                    <Gallery
                        // direction={"column"}
                        margin={4}
                        photos={images}
                        onClick={openLightbox}
                    />
                </SectionDropdown>

                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={images.map((x) => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title,
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </Section>
        </div>
    );
};

export default Vizualization;
