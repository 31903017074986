import React, { Component, useEffect } from "react";
import classNames from "classnames";
import ReactDOM from 'react-dom';
import Countdown, { CountdownRenderProps } from 'react-countdown';

// Assety
import badge from "./assets/badge.png";

// SCSS
import styles from "./style.module.scss";
import fonts from "../../vars/fonts/fonts.module.scss";
import margin from "../../vars/margin/margin.module.scss";

class Modal extends React.Component {
	
	state = {
		isDisabled: true,
	};

	componentDidMount() {
		setTimeout(() => {
			this.setState({ isDisabled: false });
		}, 2000);
	}

	closeModal = () => this.setState({ isDisabled: true });

	render() {  
		const targetDate = new Date('2023-06-30T00:00:00'); // Nastavení cílového data
		const renderer = ({
			days,
			hours,
			minutes,
			seconds,
			completed,
		  }: CountdownRenderProps) => {
			if (completed) {
			  // Vykoná se, pokud čas vypršel
			  return <span>Čas vypršel!</span>;
			} else {
			  // Zobrazí zbývající čas ve formátu "25 dní, 23 hodin, 30 minut a 5 sekund"
			  return (
				<span>
				  {days} {days === 1 ? 'den' : 'dní'}, {hours} {hours === 1 ? 'hodina' : 'hodin'},{' '}
				  {minutes} {minutes === 1 ? 'minuta' : 'minut'} a {seconds} {seconds === 1 ? 'sekunda' : 'sekund'}
				</span>
			  );
			}
		  };
		
		return (
			<div className={classNames( styles.modal, !this.state.isDisabled && styles.modalshow)}>
				<div className={classNames(styles.modalcontent, fonts.regular, fonts.center)}>
					<h2 className={fonts.bold}><b>Zvýhodněná nabídka bytů 💥</b></h2>
					{/* <h3>Už jen <Countdown date={targetDate} renderer={renderer} className={styles.cntdwn} /></h3> */}
					{/* <p className={classNames(styles.kolaudace, fonts.bold)}>Kolaudace již v srpnu 2023.</p> */}
					<h3 className={fonts.bold}>Zavolejte pro více informací:</h3>
					<p>
						<b>Ing. Jiří Oulehla, MBA</b><br />
						<a href="tel:+420722911494" className={styles.biggertext}>+420 722 911 494</a><br />
						<a href="mailto:oulehla@vlpartneri.cz">oulehla@vlpartneri.cz</a>
					</p>
					<p>
						<img src={badge} className={styles.modalbadge} />
					</p>
					<button onClick={this.closeModal}>Zavřít</button>
				</div>
			</div>
	) };
};

export default Modal;