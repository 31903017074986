import React, { Component } from "react";

// SCSS
import styles from "./style.module.scss";

// FIREBASE
import db from "../../firebase";
import { ref, onValue } from "firebase/database";
import { withAuthorization } from "../../utils/Session";

// COMPONENTS
import Loader from "../../ui/Loader";
import AdminHeader from "../../ui-admin/AdminHeader";
import SectionDropdown from "../../ui-admin/SectionDropdown";
import EditFlats from "../../ui-admin/EditFlats";
import EditFloors from "../../ui-admin/EditFloors";
import LockAdmin from "../../ui-admin/LockAdmin";
import EditParking from "../../ui-admin/EditParking";
import EditLocation from "../../ui-admin/EditLocation";
// import EditTexts from "../../ui-admin/EditTexts";
// import EditStandards from "../../ui-admin/EditStandards";
// import EditContacts from "../../ui-admin/EditContacts";

class AdminPage extends Component {
    state = {
        loading: true,
        database: {
            cellar: [],
            flats: [],
            floors: [],
            parking: [],
            contact: {
                one: {},
                two: {},
                three: {},
            },
            pdf: {
                pp1: { url: "" },
                np1: { url: "" },
                np2: { url: "" },
                np3: { url: "" },
                np4: { url: "" },

                stan1: { url: "" },
                stan2: { url: "" },
                stan3: { url: "" },
                stan4: { url: "" },
                stan5: { url: "" },
            },
            standards: {
                flats: {},
                house: {},
                system: {},
            },
            settings: {
                locked: "",
                pass: "",
            },
            texts: {
                about: {},
                infotable: {},
                intro: {},
                timeline: {},
            },
            pins: {},
        },
    };

    componentDidMount() {
        onValue(ref(db, "database"), (snapshot) => {
            if (snapshot.exists()) {
                this.setState({
                    database: snapshot.val(),
                    loading: false,
                });
            } else {
                console.log("No data available");
            }
        });
    }

    render() {
        const { database, loading } = this.state;
        return loading ? null : (
            <div id="appID">
                <Loader loading={loading} />
                <AdminHeader />
                <div className={styles.admin_container}>
                    <SectionDropdown
                        id="xscdvf"
                        title="BYTY"
                        subtitle="Správa bytů"
                        children={<EditFlats database={database} />}
                    />
                    <div className={styles.admin_content}>
                        <SectionDropdown
                            id="fgdef"
                            title="SPRÁVA PODLAŽÍ"
                            subtitle="Možnost skrýt patra"
                            children={<EditFloors database={database} />}
                        />
                        <SectionDropdown
                            id="rsdef"
                            title="ZÁMEK WEBU"
                            subtitle="Možnost uzamčít web na libovolné heslo"
                            children={<LockAdmin database={database} />}
                        />
                        <SectionDropdown
                            id="xscdvfdef"
                            title="PARKOVACÍ STÁNÍ"
                            subtitle="Správa parkovacích stání"
                            children={<EditParking database={database} />}
                        />
                        <SectionDropdown
                            id="yjiscd"
                            title="LOKALITA"
                            subtitle="Správa blízkých lokalit"
                            children={<EditLocation database={database} />}
                        />
                        {/* <SectionDropdown
                            id="yjiscd"
                            title="STANDARDY"
                            subtitle="Správa standardů"
                            children={<EditStandards database={database} />}
                        />
                        <SectionDropdown
                            id="fhtuids"
                            title="TEXTY"
                            subtitle="Úprava textů"
                            children={<EditTexts database={database} />}
                        />
                        <SectionDropdown
                            id="yaxscd"
                            title="KONTAKT"
                            subtitle="Správa kontaktních údajů"
                            children={<EditContacts database={database} />}
                        /> */}
                        <div className={styles.image} />
                    </div>
                </div>
            </div>
        );
    }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(AdminPage);
