import React from "react";
import { withRouter } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import AuthUserContext from "./context";
import * as ROUTES from "../../constants/routes";

const withAuthorization = (condition) => (Component) => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                if (!condition(user)) {
                    this.props.history.push(ROUTES.SIGN_IN);
                }
            });
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    {(authUser) =>
                        condition(authUser) ? (
                            <Component {...this.props} />
                        ) : null
                    }
                </AuthUserContext.Consumer>
            );
        }
    }

    return withRouter(WithAuthorization);
};

export default withAuthorization;
