import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";

import styles from "./style.module.scss";
import m3logo from "./assets/m3logo.svg";
import * as ROUTES from "../../constants/routes";
import { doSignOutUser } from "../../utils/Firebase";

import Icon from "../../ui/Icon";

class AdminHeader extends Component {
    render() {
        const { location } = this.props;
        return (
            <div className={styles.header}>
                <img
                    src={m3logo}
                    alt="logo"
                    className={classNames(
                        styles.logo,
                        location.pathname !== "/admin" && styles.hiddenLogo
                    )}
                />
                <div className={styles.icons}>
                    <Link to={ROUTES.LANDING}>
                        <Icon name="house" className={styles.icon} />
                    </Link>
                    {location.pathname === "/admin" && (
                        <Link to={ROUTES.ACCOUNT}>
                            <Icon name="gear" className={styles.icon} />
                        </Link>
                    )}
                    {(location.pathname === "/admin" ||
                        location.pathname === "/account") && (
                        <Icon
                            onClick={doSignOutUser}
                            name="logout"
                            className={styles.icon}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(AdminHeader);
