import React, { Component } from "react";
import { Link } from "react-router-dom";

import styles from "./style.module.scss";
import * as ROUTES from "../../constants/routes";
import { doResetPassword } from "../../utils/Firebase";

import AdminFormContainer from "../../ui-admin/AdminFormContainer";

const PasswordForgetPage = () => (
    <AdminFormContainer>
        <div className={styles.page}>
            <PasswordForgetForm />
        </div>
    </AdminFormContainer>
);

const INITIAL_STATE = {
    email: "",
    error: null,
};

class PasswordForgetFormBase extends Component {
    state = { ...INITIAL_STATE };

    onSubmit = (event) => {
        const { email } = this.state;

        doResetPassword(email)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch((error) => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, error } = this.state;

        const isInvalid = email === "";

        return (
            <form className={styles.form} onSubmit={this.onSubmit}>
                <div className={styles.title}>Obnova hesla</div>
                <input
                    className={styles.input}
                    name="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Emailová adresa"
                />
                <button
                    className={styles.input}
                    style={{ cursor: "default" }}
                    disabled={isInvalid}
                    type="submit"
                >
                    Obnovit heslo
                </button>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const PasswordForgetLink = () => (
    <p>
        <br />
        <Link to={ROUTES.PASSWORD_FORGET}>Zapomenuté heslo?</Link>
    </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = PasswordForgetFormBase;

export { PasswordForgetForm, PasswordForgetLink };
