import React from "react";
import classNames from "classnames";
// SCSS
import styles from "./style.module.scss";
import fonts from "../../vars/fonts/fonts.module.scss";
import margin from "../../vars/margin/margin.module.scss";
// COMPONENTS
import Title from "../../ui/Title";
import Section from "../../ui/Section";

const Features = ({ id }) => (
    <Section id={id} className={classNames(fonts.regular, fonts.center)}>
        <div className={margin.medium_bottom}>
            <div style={{ marginBottom: 2 }} className={fonts.h3}>
                MĚSTSKÁ ČÁST
            </div>
            <div className={fonts.h1}>ŠTÝŘICE</div>
        </div>

        <div className={classNames(fonts.regular, margin.large_bottom)}>
            Rezidencí Horní se nachází hned pod přírodní rezervací Červený
            kopec, jejíž součástí je stejnojmenná národní přírodní památka, kam
            se obyvatelé rezidence můžou vydat například na procházku či jen
            „vyčistit“ hlavu od centra Brna. Mimo to lokalita nabízí vekou
            spoustu možností pro uspokojení Vašeho sociálního života.
        </div>

        <div
            className={classNames(
                margin.large_bottom,
                fonts.right,
                styles.features_row
            )}
        >
            <div className={styles.features_cell}>
                <Title right text="Komfort" />
                Maximální komfort pro rodiny, které se nespokojí s klasickými
                byty, kterých je celá řada, ale hledají něco víc jak z pohledu
                designu, tak z pohledu provedení stavby a standardů jednotlivých
                bytových jednotek.
            </div>
            <div
                className={classNames(
                    styles.features_image,
                    styles.features_image_1
                )}
            />
        </div>

        <div
            className={classNames(
                margin.large_bottom,
                fonts.left,
                styles.features_row
            )}
        >
            <div
                className={classNames(
                    styles.features_image,
                    styles.features_image_2
                )}
            />
            <div className={styles.features_cell}>
                <Title left text="Dostupnost" />
                Okolí nabízí veškerou občanskou vybavenost v podobě mateřské a
                základní školy, střední školy, doktorů, obchodů, kaváren a
                restaurací. Další výhodou, převážně pro motoristy, je velmi
                rychlé napojení na dálnici D1 po ulici Vídeňská.
            </div>
        </div>

        <div className={classNames(styles.features_row, fonts.right)}>
            <div className={styles.features_cell}>
                <Title right text="Prostor" />
                Hlavní myšlenkou projektu je nabídnout převážně velkometrážní
                byty s velkorysými prostory. Velikosti bytů se pohybují od 2+kk
                až po 5+kk.
            </div>
            <div
                className={classNames(
                    styles.features_image,
                    styles.features_image_3
                )}
            />
        </div>
    </Section>
);

export default Features;
