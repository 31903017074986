import React from "react";
import { TweenLite } from "gsap";
import classNames from "classnames";
// SCSS
import styles from "./style.module.scss";
import fonts from "../../vars/fonts/fonts.module.scss";
import margin from "../../vars/margin/margin.module.scss";
// COMPONENTS
import Button from "../../ui/Button";
import Section from "../../ui/Section";

class About extends React.Component {
    scrollToContact = () => {
        TweenLite.to(window, 1, {
            scrollTo: { y: "#prehledbytu", offsetY: 20, autoKill: false },
        });
    };

    render() {
        return (
            <React.Fragment>
                <Section className={fonts.center}>
                    <div className={classNames(fonts.h3, margin.medium_bottom)}>
                        <div>Místo pro ideální domov</div>

                        <div
                            className={classNames(
                                fonts.h1,
                                styles.about_heading
                            )}
                        >
                            HORNÍ
                        </div>

                        <div>ŠTÝŘICE</div>
                    </div>

                    <div
                        className={classNames(
                            fonts.regular,
                            margin.medium_bottom
                        )}
                    >
                        Vítejte na stránkách projektu Rezidence Horní, který
                        představuje spojení luxusního bydlení v krásné lokalitě
                        plné zeleně a klidu. Projekt je rozdělen na dva
                        šestipatrové objekty, které nabízení bytové jednotky o
                        velikosti 2+kk až 5+kk a dvě podzemní patra určená pro
                        parkování.
                    </div>

                    <Button
                        onClick={this.scrollToContact}
                        large
                        title="- prohlédnout -"
                    />
                </Section>

                <div className={styles.about_image} />
            </React.Fragment>
        );
    }
}

export default About;
